html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
a {
  background-color: transparent;
}
a:active,
a:hover {
  outline: 0;
}
abbr[title] {
  border-bottom: 1px dotted;
}
b,
strong {
  font-weight: bold;
}
dfn {
  font-style: italic;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
mark {
  background: #ff0;
  color: #000;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
img {
  border: 0;
}
svg:not(:root) {
  overflow: hidden;
}
figure {
  margin: 1em 40px;
}
hr {
  box-sizing: content-box;
  height: 0;
}
pre {
  overflow: auto;
}
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
button {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
html input[disabled] {
  cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input {
  line-height: normal;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
legend {
  border: 0;
  padding: 0;
}
textarea {
  overflow: auto;
}
optgroup {
  font-weight: bold;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
td,
th {
  padding: 0;
}

@-ms-viewport {
  width: device-width;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
html {
  margin: 0!important;
}
body {
  position: relative;
  overflow-x: hidden;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Muli', sans-serif;
  font-size: 16px;
  color: #6C645B;
  margin-top: 0!important;
}
body._overflow {
  overflow: hidden!important;
}
::selection {
  background: #e5d18e;
  color: rgba(255, 255, 255, 0.8);
  text-shadow: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  line-height: inherit;
}
input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
a,
button {
  outline: 0;
}
p {
  margin: 0;
}
a {
  transition: color 0.3s ease;
}
a:hover {
  color: #004872;
}
a:focus {
  color: #003859;
}
a:active {
  color: #002e49;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  z-index: 1;
}
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
}
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}
.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
.owl-carousel .owl-item img {
  display: block;
}
.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}
.owl-carousel .owl-prev,
.owl-carousel .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel button.owl-prev,
.owl-carousel button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  border: none;
}
.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}
.owl-carousel.owl-hidden {
  opacity: 0;
}
.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}
.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}
.owl-carousel.owl-rtl {
  direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
  float: right;
}
.no-js .owl-carousel {
  display: block;
}
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
  z-index: 0;
}
.owl-carousel .owl-animated-out {
  z-index: 1;
}
.owl-carousel .fadeOut {
  animation-name: fadeOut;
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.owl-height {
  transition: height 500ms ease-in-out;
}
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease;
}
.owl-carousel .owl-item .owl-lazy[src^=""],
.owl-carousel .owl-item .owl-lazy:not([src]) {
  max-height: 0;
}
.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}
.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease;
}
.owl-carousel .owl-video-play-icon:hover {
  transform: scale(1.3, 1.3);
}
.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}
.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease;
}
.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}

.u-clearfix:before,
.u-clearfix:after {
  content: '';
  display: table;
}
.u-clearfix:after {
  clear: both;
}
.u-pull-right {
  float: right !important;
}
.u-pull-left {
  float: left !important;
}
.u-list-clean {
  margin: 0;
  padding: 0;
}
.u-list-clean > li {
  list-style: none;
}
.u-list-inline {
  font-size: 0;
  line-height: 0;
}
.u-list-inline > li {
  vertical-align: top;
  display: inline-block;
  font-size: 16px;
  line-height: normal;
}
.u-text-center {
  text-align: center;
}
.u-text-right {
  text-align: right;
}
.u-text-left {
  text-align: left;
}
.u-text-overflow {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.u-srOnly {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}
.u-block-overflow {
  overflow: hidden !important;
}
.u-visible-xs,
.u-visible-sm,
.u-visible-md,
.u-visible-lg {
  display: none !important;
}
.u-visible-xs-block,
.u-visible-xs-inline,
.u-visible-xs-inlineBlock,
.u-visible-sm-block,
.u-visible-sm-inline,
.u-visible-sm-inlineBlock,
.u-visible-md-block,
.u-visible-md-inline,
.u-visible-md-inlineBlock,
.u-visible-lg-block,
.u-visible-lg-inline,
.u-visible-lg-inlineBlock {
  display: none !important;
}
@media (max-width: 767px) {
  .u-visible-xs {
    display: block !important;
  }
  table.u-visible-xs {
    display: table !important;
  }
  tr.u-visible-xs {
    display: table-row !important;
  }
  th.u-visible-xs,
  td.u-visible-xs {
    display: table-cell !important;
  }
}
@media (max-width: 767px) {
  .u-visible-xs-block {
    display: block !important;
  }
}
@media (max-width: 767px) {
  .u-visible-xs-inline {
    display: inline !important;
  }
}
@media (max-width: 767px) {
  .u-visible-xs-inlineBlock {
    display: inline-block !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-visible-sm {
    display: block !important;
  }
  table.u-visible-sm {
    display: table !important;
  }
  tr.u-visible-sm {
    display: table-row !important;
  }
  th.u-visible-sm,
  td.u-visible-sm {
    display: table-cell !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-visible-sm-block {
    display: block !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-visible-sm-inline {
    display: inline !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-visible-sm-inlineBlock {
    display: inline-block !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-visible-md {
    display: block !important;
  }
  table.u-visible-md {
    display: table !important;
  }
  tr.u-visible-md {
    display: table-row !important;
  }
  th.u-visible-md,
  td.u-visible-md {
    display: table-cell !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-visible-md-block {
    display: block !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-visible-md-inline {
    display: inline !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-visible-md-inlineBlock {
    display: inline-block !important;
  }
}
@media (min-width: 1200px) {
  .u-visible-lg {
    display: block !important;
  }
  table.u-visible-lg {
    display: table !important;
  }
  tr.u-visible-lg {
    display: table-row !important;
  }
  th.u-visible-lg,
  td.u-visible-lg {
    display: table-cell !important;
  }
}
@media (min-width: 1200px) {
  .u-visible-lg-block {
    display: block !important;
  }
}
@media (min-width: 1200px) {
  .u-visible-lg-inline {
    display: inline !important;
  }
}
@media (min-width: 1200px) {
  .u-visible-lg-inlineBlock {
    display: inline-block !important;
  }
}
@media (max-width: 767px) {
  .u-hidden-xs {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-hidden-sm {
    display: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-hidden-md {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .u-hidden-lg {
    display: none !important;
  }
}

.grid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.grid:before,
.grid:after {
  content: '';
  display: table;
}
.grid:after {
  clear: both;
}
@media (min-width: 768px) {
  .grid {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .grid {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .grid {
    width: 1170px;
  }
}
.grid._fluid {
  width: 100%;
  max-width: 1440px;
}
.grid-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}
.grid-row._gutter-false {
  margin: 0;
}
.grid-row._gutter-false > .grid-col {
  padding: 0;
}
.grid-row._align-start-xs {
  -ms-flex-pack: start;
  justify-content: flex-start;
  text-align: start;
}
@media (min-width: 768px) {
  .grid-row._align-start-sm {
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-align: start;
  }
}
@media (min-width: 992px) {
  .grid-row._align-start-md {
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-align: start;
  }
}
@media (min-width: 1200px) {
  .grid-row._align-start-lg {
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-align: start;
  }
}
.grid-row._align-center-xs {
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}
@media (min-width: 768px) {
  .grid-row._align-center-sm {
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }
}
@media (min-width: 992px) {
  .grid-row._align-center-md {
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }
}
@media (min-width: 1200px) {
  .grid-row._align-center-lg {
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }
}
.grid-row._align-end-xs {
  -ms-flex-pack: end;
  justify-content: flex-end;
  text-align: end;
}
@media (min-width: 768px) {
  .grid-row._align-end-sm {
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-align: end;
  }
}
@media (min-width: 992px) {
  .grid-row._align-end-md {
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-align: end;
  }
}
@media (min-width: 1200px) {
  .grid-row._align-end-lg {
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-align: end;
  }
}
.grid-row._align-top-xs {
  -ms-flex-align: start;
  align-items: flex-start;
}
@media (min-width: 768px) {
  .grid-row._align-top-sm {
    -ms-flex-align: start;
    align-items: flex-start;
  }
}
@media (min-width: 992px) {
  .grid-row._align-top-md {
    -ms-flex-align: start;
    align-items: flex-start;
  }
}
@media (min-width: 1200px) {
  .grid-row._align-top-lg {
    -ms-flex-align: start;
    align-items: flex-start;
  }
}
.grid-row._align-middle-xs {
  -ms-flex-align: center;
  align-items: center;
}
@media (min-width: 768px) {
  .grid-row._align-middle-sm {
    -ms-flex-align: center;
    align-items: center;
  }
}
@media (min-width: 992px) {
  .grid-row._align-middle-md {
    -ms-flex-align: center;
    align-items: center;
  }
}
@media (min-width: 1200px) {
  .grid-row._align-middle-lg {
    -ms-flex-align: center;
    align-items: center;
  }
}
.grid-row._align-bottom-xs {
  -ms-flex-align: end;
  align-items: flex-end;
}
@media (min-width: 768px) {
  .grid-row._align-bottom-sm {
    -ms-flex-align: end;
    align-items: flex-end;
  }
}
@media (min-width: 992px) {
  .grid-row._align-bottom-md {
    -ms-flex-align: end;
    align-items: flex-end;
  }
}
@media (min-width: 1200px) {
  .grid-row._align-bottom-lg {
    -ms-flex-align: end;
    align-items: flex-end;
  }
}
.grid-row._dist-around-xs {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}
@media (min-width: 768px) {
  .grid-row._dist-around-sm {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
}
@media (min-width: 992px) {
  .grid-row._dist-around-md {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
}
@media (min-width: 1200px) {
  .grid-row._dist-around-lg {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
}
.grid-row._dist-between-xs {
  -ms-flex-pack: justify;
  justify-content: space-between;
}
@media (min-width: 768px) {
  .grid-row._dist-between-sm {
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}
@media (min-width: 992px) {
  .grid-row._dist-between-md {
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}
@media (min-width: 1200px) {
  .grid-row._dist-between-lg {
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}
.grid-row._reverse {
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
.grid-col {
  position: relative;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}
.grid-col._order-first-xs {
  -ms-flex-order: -1;
  order: -1;
}
@media (min-width: 768px) {
  .grid-col._order-first-sm {
    -ms-flex-order: -1;
    order: -1;
  }
}
@media (min-width: 992px) {
  .grid-col._order-first-md {
    -ms-flex-order: -1;
    order: -1;
  }
}
@media (min-width: 1200px) {
  .grid-col._order-first-lg {
    -ms-flex-order: -1;
    order: -1;
  }
}
.grid-col._order-last-xs {
  -ms-flex-order: 1;
  order: 1;
}
@media (min-width: 768px) {
  .grid-col._order-last-sm {
    -ms-flex-order: 1;
    order: 1;
  }
}
@media (min-width: 992px) {
  .grid-col._order-last-md {
    -ms-flex-order: 1;
    order: 1;
  }
}
@media (min-width: 1200px) {
  .grid-col._order-last-lg {
    -ms-flex-order: 1;
    order: 1;
  }
}
.grid-col._reverse {
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}
.grid-col._xs-12 {
  max-width: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
}
.grid-col._xs-11 {
  max-width: 91.66666667%;
  -ms-flex-preferred-size: 91.66666667%;
  flex-basis: 91.66666667%;
}
.grid-col._xs-10 {
  max-width: 83.33333333%;
  -ms-flex-preferred-size: 83.33333333%;
  flex-basis: 83.33333333%;
}
.grid-col._xs-9 {
  max-width: 75%;
  -ms-flex-preferred-size: 75%;
  flex-basis: 75%;
}
.grid-col._xs-8 {
  max-width: 66.66666667%;
  -ms-flex-preferred-size: 66.66666667%;
  flex-basis: 66.66666667%;
}
.grid-col._xs-7 {
  max-width: 58.33333333%;
  -ms-flex-preferred-size: 58.33333333%;
  flex-basis: 58.33333333%;
}
.grid-col._xs-6 {
  max-width: 50%;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
}
.grid-col._xs-5 {
  max-width: 41.66666667%;
  -ms-flex-preferred-size: 41.66666667%;
  flex-basis: 41.66666667%;
}
.grid-col._xs-4 {
  max-width: 33.33333333%;
  -ms-flex-preferred-size: 33.33333333%;
  flex-basis: 33.33333333%;
}
.grid-col._xs-3 {
  max-width: 25%;
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%;
}
.grid-col._xs-2 {
  max-width: 16.66666667%;
  -ms-flex-preferred-size: 16.66666667%;
  flex-basis: 16.66666667%;
}
.grid-col._xs-1 {
  max-width: 8.33333333%;
  -ms-flex-preferred-size: 8.33333333%;
  flex-basis: 8.33333333%;
}
.grid-col._xs-pull-12 {
  right: 100%;
}
.grid-col._xs-pull-11 {
  right: 91.66666667%;
}
.grid-col._xs-pull-10 {
  right: 83.33333333%;
}
.grid-col._xs-pull-9 {
  right: 75%;
}
.grid-col._xs-pull-8 {
  right: 66.66666667%;
}
.grid-col._xs-pull-7 {
  right: 58.33333333%;
}
.grid-col._xs-pull-6 {
  right: 50%;
}
.grid-col._xs-pull-5 {
  right: 41.66666667%;
}
.grid-col._xs-pull-4 {
  right: 33.33333333%;
}
.grid-col._xs-pull-3 {
  right: 25%;
}
.grid-col._xs-pull-2 {
  right: 16.66666667%;
}
.grid-col._xs-pull-1 {
  right: 8.33333333%;
}
.grid-col._xs-pull-0 {
  right: auto;
}
.grid-col._xs-push-12 {
  left: 100%;
}
.grid-col._xs-push-11 {
  left: 91.66666667%;
}
.grid-col._xs-push-10 {
  left: 83.33333333%;
}
.grid-col._xs-push-9 {
  left: 75%;
}
.grid-col._xs-push-8 {
  left: 66.66666667%;
}
.grid-col._xs-push-7 {
  left: 58.33333333%;
}
.grid-col._xs-push-6 {
  left: 50%;
}
.grid-col._xs-push-5 {
  left: 41.66666667%;
}
.grid-col._xs-push-4 {
  left: 33.33333333%;
}
.grid-col._xs-push-3 {
  left: 25%;
}
.grid-col._xs-push-2 {
  left: 16.66666667%;
}
.grid-col._xs-push-1 {
  left: 8.33333333%;
}
.grid-col._xs-push-0 {
  left: auto;
}
.grid-col._xs-offset-12 {
  margin-left: 100%;
}
.grid-col._xs-offset-11 {
  margin-left: 91.66666667%;
}
.grid-col._xs-offset-10 {
  margin-left: 83.33333333%;
}
.grid-col._xs-offset-9 {
  margin-left: 75%;
}
.grid-col._xs-offset-8 {
  margin-left: 66.66666667%;
}
.grid-col._xs-offset-7 {
  margin-left: 58.33333333%;
}
.grid-col._xs-offset-6 {
  margin-left: 50%;
}
.grid-col._xs-offset-5 {
  margin-left: 41.66666667%;
}
.grid-col._xs-offset-4 {
  margin-left: 33.33333333%;
}
.grid-col._xs-offset-3 {
  margin-left: 25%;
}
.grid-col._xs-offset-2 {
  margin-left: 16.66666667%;
}
.grid-col._xs-offset-1 {
  margin-left: 8.33333333%;
}
.grid-col._xs-offset-0 {
  margin-left: 0%;
}
@media (min-width: 768px) {
  .grid-col._sm-12 {
    max-width: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
  .grid-col._sm-11 {
    max-width: 91.66666667%;
    -ms-flex-preferred-size: 91.66666667%;
    flex-basis: 91.66666667%;
  }
  .grid-col._sm-10 {
    max-width: 83.33333333%;
    -ms-flex-preferred-size: 83.33333333%;
    flex-basis: 83.33333333%;
  }
  .grid-col._sm-9 {
    max-width: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
  }
  .grid-col._sm-8 {
    max-width: 66.66666667%;
    -ms-flex-preferred-size: 66.66666667%;
    flex-basis: 66.66666667%;
  }
  .grid-col._sm-7 {
    max-width: 58.33333333%;
    -ms-flex-preferred-size: 58.33333333%;
    flex-basis: 58.33333333%;
  }
  .grid-col._sm-6 {
    max-width: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }
  .grid-col._sm-5 {
    max-width: 41.66666667%;
    -ms-flex-preferred-size: 41.66666667%;
    flex-basis: 41.66666667%;
  }
  .grid-col._sm-4 {
    max-width: 33.33333333%;
    -ms-flex-preferred-size: 33.33333333%;
    flex-basis: 33.33333333%;
  }
  .grid-col._sm-3 {
    max-width: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
  }
  .grid-col._sm-2 {
    max-width: 16.66666667%;
    -ms-flex-preferred-size: 16.66666667%;
    flex-basis: 16.66666667%;
  }
  .grid-col._sm-1 {
    max-width: 8.33333333%;
    -ms-flex-preferred-size: 8.33333333%;
    flex-basis: 8.33333333%;
  }
  .grid-col._sm-pull-12 {
    right: 100%;
  }
  .grid-col._sm-pull-11 {
    right: 91.66666667%;
  }
  .grid-col._sm-pull-10 {
    right: 83.33333333%;
  }
  .grid-col._sm-pull-9 {
    right: 75%;
  }
  .grid-col._sm-pull-8 {
    right: 66.66666667%;
  }
  .grid-col._sm-pull-7 {
    right: 58.33333333%;
  }
  .grid-col._sm-pull-6 {
    right: 50%;
  }
  .grid-col._sm-pull-5 {
    right: 41.66666667%;
  }
  .grid-col._sm-pull-4 {
    right: 33.33333333%;
  }
  .grid-col._sm-pull-3 {
    right: 25%;
  }
  .grid-col._sm-pull-2 {
    right: 16.66666667%;
  }
  .grid-col._sm-pull-1 {
    right: 8.33333333%;
  }
  .grid-col._sm-pull-0 {
    right: auto;
  }
  .grid-col._sm-push-12 {
    left: 100%;
  }
  .grid-col._sm-push-11 {
    left: 91.66666667%;
  }
  .grid-col._sm-push-10 {
    left: 83.33333333%;
  }
  .grid-col._sm-push-9 {
    left: 75%;
  }
  .grid-col._sm-push-8 {
    left: 66.66666667%;
  }
  .grid-col._sm-push-7 {
    left: 58.33333333%;
  }
  .grid-col._sm-push-6 {
    left: 50%;
  }
  .grid-col._sm-push-5 {
    left: 41.66666667%;
  }
  .grid-col._sm-push-4 {
    left: 33.33333333%;
  }
  .grid-col._sm-push-3 {
    left: 25%;
  }
  .grid-col._sm-push-2 {
    left: 16.66666667%;
  }
  .grid-col._sm-push-1 {
    left: 8.33333333%;
  }
  .grid-col._sm-push-0 {
    left: auto;
  }
  .grid-col._sm-offset-12 {
    margin-left: 100%;
  }
  .grid-col._sm-offset-11 {
    margin-left: 91.66666667%;
  }
  .grid-col._sm-offset-10 {
    margin-left: 83.33333333%;
  }
  .grid-col._sm-offset-9 {
    margin-left: 75%;
  }
  .grid-col._sm-offset-8 {
    margin-left: 66.66666667%;
  }
  .grid-col._sm-offset-7 {
    margin-left: 58.33333333%;
  }
  .grid-col._sm-offset-6 {
    margin-left: 50%;
  }
  .grid-col._sm-offset-5 {
    margin-left: 41.66666667%;
  }
  .grid-col._sm-offset-4 {
    margin-left: 33.33333333%;
  }
  .grid-col._sm-offset-3 {
    margin-left: 25%;
  }
  .grid-col._sm-offset-2 {
    margin-left: 16.66666667%;
  }
  .grid-col._sm-offset-1 {
    margin-left: 8.33333333%;
  }
  .grid-col._sm-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 992px) {
  .grid-col._md-12 {
    max-width: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
  .grid-col._md-11 {
    max-width: 91.66666667%;
    -ms-flex-preferred-size: 91.66666667%;
    flex-basis: 91.66666667%;
  }
  .grid-col._md-10 {
    max-width: 83.33333333%;
    -ms-flex-preferred-size: 83.33333333%;
    flex-basis: 83.33333333%;
  }
  .grid-col._md-9 {
    max-width: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
  }
  .grid-col._md-8 {
    max-width: 66.66666667%;
    -ms-flex-preferred-size: 66.66666667%;
    flex-basis: 66.66666667%;
  }
  .grid-col._md-7 {
    max-width: 58.33333333%;
    -ms-flex-preferred-size: 58.33333333%;
    flex-basis: 58.33333333%;
  }
  .grid-col._md-6 {
    max-width: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }
  .grid-col._md-5 {
    max-width: 41.66666667%;
    -ms-flex-preferred-size: 41.66666667%;
    flex-basis: 41.66666667%;
  }
  .grid-col._md-4 {
    max-width: 33.33333333%;
    -ms-flex-preferred-size: 33.33333333%;
    flex-basis: 33.33333333%;
  }
  .grid-col._md-3 {
    max-width: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
  }
  .grid-col._md-2 {
    max-width: 16.66666667%;
    -ms-flex-preferred-size: 16.66666667%;
    flex-basis: 16.66666667%;
  }
  .grid-col._md-1 {
    max-width: 8.33333333%;
    -ms-flex-preferred-size: 8.33333333%;
    flex-basis: 8.33333333%;
  }
  .grid-col._md-pull-12 {
    right: 100%;
  }
  .grid-col._md-pull-11 {
    right: 91.66666667%;
  }
  .grid-col._md-pull-10 {
    right: 83.33333333%;
  }
  .grid-col._md-pull-9 {
    right: 75%;
  }
  .grid-col._md-pull-8 {
    right: 66.66666667%;
  }
  .grid-col._md-pull-7 {
    right: 58.33333333%;
  }
  .grid-col._md-pull-6 {
    right: 50%;
  }
  .grid-col._md-pull-5 {
    right: 41.66666667%;
  }
  .grid-col._md-pull-4 {
    right: 33.33333333%;
  }
  .grid-col._md-pull-3 {
    right: 25%;
  }
  .grid-col._md-pull-2 {
    right: 16.66666667%;
  }
  .grid-col._md-pull-1 {
    right: 8.33333333%;
  }
  .grid-col._md-pull-0 {
    right: auto;
  }
  .grid-col._md-push-12 {
    left: 100%;
  }
  .grid-col._md-push-11 {
    left: 91.66666667%;
  }
  .grid-col._md-push-10 {
    left: 83.33333333%;
  }
  .grid-col._md-push-9 {
    left: 75%;
  }
  .grid-col._md-push-8 {
    left: 66.66666667%;
  }
  .grid-col._md-push-7 {
    left: 58.33333333%;
  }
  .grid-col._md-push-6 {
    left: 50%;
  }
  .grid-col._md-push-5 {
    left: 41.66666667%;
  }
  .grid-col._md-push-4 {
    left: 33.33333333%;
  }
  .grid-col._md-push-3 {
    left: 25%;
  }
  .grid-col._md-push-2 {
    left: 16.66666667%;
  }
  .grid-col._md-push-1 {
    left: 8.33333333%;
  }
  .grid-col._md-push-0 {
    left: auto;
  }
  .grid-col._md-offset-12 {
    margin-left: 100%;
  }
  .grid-col._md-offset-11 {
    margin-left: 91.66666667%;
  }
  .grid-col._md-offset-10 {
    margin-left: 83.33333333%;
  }
  .grid-col._md-offset-9 {
    margin-left: 75%;
  }
  .grid-col._md-offset-8 {
    margin-left: 66.66666667%;
  }
  .grid-col._md-offset-7 {
    margin-left: 58.33333333%;
  }
  .grid-col._md-offset-6 {
    margin-left: 50%;
  }
  .grid-col._md-offset-5 {
    margin-left: 41.66666667%;
  }
  .grid-col._md-offset-4 {
    margin-left: 33.33333333%;
  }
  .grid-col._md-offset-3 {
    margin-left: 25%;
  }
  .grid-col._md-offset-2 {
    margin-left: 16.66666667%;
  }
  .grid-col._md-offset-1 {
    margin-left: 8.33333333%;
  }
  .grid-col._md-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 1200px) {
  .grid-col._lg-12 {
    max-width: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
  .grid-col._lg-11 {
    max-width: 91.66666667%;
    -ms-flex-preferred-size: 91.66666667%;
    flex-basis: 91.66666667%;
  }
  .grid-col._lg-10 {
    max-width: 83.33333333%;
    -ms-flex-preferred-size: 83.33333333%;
    flex-basis: 83.33333333%;
  }
  .grid-col._lg-9 {
    max-width: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
  }
  .grid-col._lg-8 {
    max-width: 66.66666667%;
    -ms-flex-preferred-size: 66.66666667%;
    flex-basis: 66.66666667%;
  }
  .grid-col._lg-7 {
    max-width: 58.33333333%;
    -ms-flex-preferred-size: 58.33333333%;
    flex-basis: 58.33333333%;
  }
  .grid-col._lg-6 {
    max-width: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }
  .grid-col._lg-5 {
    max-width: 41.66666667%;
    -ms-flex-preferred-size: 41.66666667%;
    flex-basis: 41.66666667%;
  }
  .grid-col._lg-4 {
    max-width: 33.33333333%;
    -ms-flex-preferred-size: 33.33333333%;
    flex-basis: 33.33333333%;
  }
  .grid-col._lg-3 {
    max-width: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
  }
  .grid-col._lg-2 {
    max-width: 16.66666667%;
    -ms-flex-preferred-size: 16.66666667%;
    flex-basis: 16.66666667%;
  }
  .grid-col._lg-1 {
    max-width: 8.33333333%;
    -ms-flex-preferred-size: 8.33333333%;
    flex-basis: 8.33333333%;
  }
  .grid-col._lg-pull-12 {
    right: 100%;
  }
  .grid-col._lg-pull-11 {
    right: 91.66666667%;
  }
  .grid-col._lg-pull-10 {
    right: 83.33333333%;
  }
  .grid-col._lg-pull-9 {
    right: 75%;
  }
  .grid-col._lg-pull-8 {
    right: 66.66666667%;
  }
  .grid-col._lg-pull-7 {
    right: 58.33333333%;
  }
  .grid-col._lg-pull-6 {
    right: 50%;
  }
  .grid-col._lg-pull-5 {
    right: 41.66666667%;
  }
  .grid-col._lg-pull-4 {
    right: 33.33333333%;
  }
  .grid-col._lg-pull-3 {
    right: 25%;
  }
  .grid-col._lg-pull-2 {
    right: 16.66666667%;
  }
  .grid-col._lg-pull-1 {
    right: 8.33333333%;
  }
  .grid-col._lg-pull-0 {
    right: auto;
  }
  .grid-col._lg-push-12 {
    left: 100%;
  }
  .grid-col._lg-push-11 {
    left: 91.66666667%;
  }
  .grid-col._lg-push-10 {
    left: 83.33333333%;
  }
  .grid-col._lg-push-9 {
    left: 75%;
  }
  .grid-col._lg-push-8 {
    left: 66.66666667%;
  }
  .grid-col._lg-push-7 {
    left: 58.33333333%;
  }
  .grid-col._lg-push-6 {
    left: 50%;
  }
  .grid-col._lg-push-5 {
    left: 41.66666667%;
  }
  .grid-col._lg-push-4 {
    left: 33.33333333%;
  }
  .grid-col._lg-push-3 {
    left: 25%;
  }
  .grid-col._lg-push-2 {
    left: 16.66666667%;
  }
  .grid-col._lg-push-1 {
    left: 8.33333333%;
  }
  .grid-col._lg-push-0 {
    left: auto;
  }
  .grid-col._lg-offset-12 {
    margin-left: 100%;
  }
  .grid-col._lg-offset-11 {
    margin-left: 91.66666667%;
  }
  .grid-col._lg-offset-10 {
    margin-left: 83.33333333%;
  }
  .grid-col._lg-offset-9 {
    margin-left: 75%;
  }
  .grid-col._lg-offset-8 {
    margin-left: 66.66666667%;
  }
  .grid-col._lg-offset-7 {
    margin-left: 58.33333333%;
  }
  .grid-col._lg-offset-6 {
    margin-left: 50%;
  }
  .grid-col._lg-offset-5 {
    margin-left: 41.66666667%;
  }
  .grid-col._lg-offset-4 {
    margin-left: 33.33333333%;
  }
  .grid-col._lg-offset-3 {
    margin-left: 25%;
  }
  .grid-col._lg-offset-2 {
    margin-left: 16.66666667%;
  }
  .grid-col._lg-offset-1 {
    margin-left: 8.33333333%;
  }
  .grid-col._lg-offset-0 {
    margin-left: 0%;
  }
}

.cover {
  height: 100vh;
  position: relative;
  background-position: top right;
  background-attachment: fixed;
  background-size: cover;
  min-height: 700px;
  max-height: 900px;
}
@media screen and (max-width: 767px) {
  .cover {
    background-attachment: scroll;
  }
}
.cover:after {
  position: absolute;
  content: ' ';
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #6C645B;
  opacity: 0.8;
  z-index: 2;
}
.cover:before {
  position: absolute;
  content: ' ';
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  left: 0;
  right: 0;
  z-index: 9;
  background-image: url('../static/images/bottom.svg');
  height: 80px;
  bottom: -1px;
}
@media screen and (max-width: 991px) {
  .cover:before {
    height: 50px;
  }
}
@media screen and (max-width: 767px) {
  .cover:before {
    height: 30px;
  }
}
.cover-logo {
  text-align: center;
  padding: 100px 0;
  display: block;
  position: relative;
  z-index: 10;
}
@media screen and (max-width: 1050px) {
  .cover-logo {
    padding: 40px 0 50px;
  }
}
.cover-logo._sm {
  margin: 0 0 40px;
}
@media screen and (max-width: 767px) {
  .cover-logo._sm {
    margin-bottom: 0;
  }
}
.cover-logo._sm img {
  max-width: 200px;
}
.cover-logo img {
  display: inline-block;
  width: 100%;
  max-width: 248px;
  margin: 0 auto;
  transition: .3s ease;
}
@media screen and (max-width: 767px) {
  .cover-logo img {
    max-width: 200px;
  }
}
.cover-logo img:hover {
  transform: scale(1.05);
}
.cover-logo a {
  display: block;
}
.cover-top {
  margin-top: -300px;
}
@media screen and (max-width: 767px) {
  .cover-top {
    margin-top: -370px;
  }
}
.cover-video {
  padding: 6px;
  border-radius: 10px;
  background: linear-gradient(-135deg, #9E7337 0%, #F7ED93 51%, #D2B148 100%);
  display: block;
}
@media screen and (max-width: 767px) {
  .cover-video {
    padding: 3px;
  }
}
.cover-video:hover .cover-videoImg {
  transform: scale(1.15);
}
.cover-video:hover .cover-videoInner:after {
  left: calc(50% - 35px);
  top: calc(50% - 35px);
  width: 70px;
  height: 70px;
  background-size: 70px 70px;
}
.cover-videoInner {
  width: 100%;
  padding-bottom: 400px;
  display: block;
  background: #6C645B;
  box-shadow: 0 25px 30px -20px rgba(233, 215, 119, 0.4);
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}
.cover-videoInner:after {
  transition: .4s ease;
  position: absolute;
  content: ' ';
  left: calc(50% - 40px);
  right: 0;
  top: calc(50% - 40px);
  bottom: 0;
  width: 80px;
  height: 80px;
  background-image: url('../static/images/icon/play.svg');
  background-repeat: no-repeat;
  background-size: 80px 80px;
}
.cover-videoImg {
  transition: 1.1s ease;
  background-size: cover;
  background-position: top center;
  position: absolute;
  content: ' ';
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.cover-mob {
  display: none;
  width: 30px;
  height: 30px;
  border: none;
  background-color: inherit;
  background-image: url('../static/images/icon/nav.svg');
  background-size: 30px 30px;
  background-repeat: no-repeat;
}
@media screen and (max-width: 991px) {
  .cover-mob {
    display: block;
  }
}
.cover .menu {
  margin: 0;
}
.cover-header {
  padding: 30px 0;
  z-index: 999;
  position: relative;
}
.cover-header._fixed {
  position: fixed;
  width: 100%;
  background: none;
  transition: .4s ease;
}
@media screen and (max-width: 991px) {
  .cover-header._fixed {
    background: #6C645B;
  }
}
.cover-header._stick {
  padding: 10px 0;
  background: #6C645B;
  box-shadow: 0 2px 5px 0 rgba(41, 41, 38, 0.2);
}
@media screen and (max-width: 991px) {
  .cover-header {
    padding: 10px 0;
    background: #6C645B;
    box-shadow: 0 2px 5px 0 rgba(41, 41, 38, 0.2);
  }
}
.cover-nav {
  text-align: left;
}
@media screen and (max-width: 991px) {
  .cover-nav .menu {
    display: none;
  }
}
.cover-nav ul {
  padding: 0;
  margin: 0;
}
.cover-nav li {
  display: inline-block;
  margin-right: 15px;
}
.cover-nav li:last-child {
  margin: 0;
}
.cover-nav li.current-menu-item a {
  color: #D6B850;
}
.cover-nav a {
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 1;
  font-weight: 400;
  text-decoration: none;
}
.cover-nav a:hover,
.cover-nav a:focus,
.cover-nav a:active {
  color: #D6B850;
}
.cover-soc {
  padding: 0;
  text-align: right;
  display: inline-block;
  vertical-align: middle;
}
.cover-soc li {
  width: 25px;
  height: 25px;
  margin-right: 30px;
}
@media screen and (max-width: 991px) {
  .cover-soc li {
    margin-right: 15px;
  }
}
.cover-soc li:last-child {
  margin-right: 60px;
}
@media screen and (max-width: 991px) {
  .cover-soc li:last-child {
    margin-right: 45px;
  }
}
.cover-soc .icon {
  display: block;
  width: 23px;
  height: 23px;
  background-size: 23px 23px;
  background-repeat: no-repeat;
  transition: .4s ease;
}
.cover-soc .icon:hover {
  transform: scale(1.3);
}
.cover-soc .icon._inst {
  background-image: url('../static/images/soc/inst.svg');
}
.cover-soc .icon._tel {
  background-image: url('../static/images/soc/tel.svg');
}
.cover-soc .icon._wh {
  background-image: url('../static/images/soc/wh.svg');
}
.cover-soc .icon._fb {
  background-image: url('../static/images/soc/fb.svg');
}
.cover-right {
  text-align: right;
}
.cover-lang {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}
@media screen and (max-width: 991px) {
  .cover-lang {
    margin-right: 15px;
  }
}
.cover-langList {
  position: absolute;
  content: ' ';
  top: 0;
  left: 0;
  text-align: center;
  padding: 0;
  display: none;
}
.cover-langList:after {
  position: absolute;
  content: ' ';
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #6C645B;
  border-radius: 30px;
  z-index: -1;
  box-shadow: 0 2px 5px 0 rgba(41, 41, 38, 0.2);
}
.cover-langList._in {
  display: block;
}
.cover-langList li {
  padding: 1px 0;
  margin: 0 0 10px;
  position: relative;
}
.cover-langList li:first-child {
  opacity: 0;
}
.cover-langList li:first-child:after {
  display: none;
}
.cover-langList li:after {
  position: absolute;
  content: ' ';
  left: 0;
  right: 0;
  bottom: 0;
  border-bottom: 1.5px solid #D6B850;
}
.cover-langList a {
  text-decoration: none;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  font-weight: 700;
}
.cover-langList a:hover {
  color: #D6B850;
}
.cover-btn {
  background: none;
  padding: 0;
  width: 27px;
  height: 27px;
  position: relative;
  font-size: 11px;
  line-height: 20px;
  color: #fff;
  font-weight: 700;
  border: 1.5px solid #D6B850;
  border-radius: 100%;
}
.cover-btn._in:after {
  transform: rotate(90deg);
}
.cover-btn:after {
  transition: .3s ease;
  position: absolute;
  content: ' ';
  top: 50%;
  right: -15px;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #fff;
}
.cover-mobile {
  background: #6C645B;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  transform: translateY(-100%);
  transition: .3s linear;
  box-shadow: 0 2px 5px 0 rgba(41, 41, 38, 0.2);
}
.cover-mobile._in {
  transform: translateY(0);
}
.cover-mobile .menu {
  padding: 60px 0 0 0;
}
.cover-mobile li a {
  font-size: 20px;
  line-height: 1.3;
  color: #fff;
  text-decoration: none;
  font-weight: 400;
  text-transform: uppercase;
}
.cover-mobile li {
  margin-bottom: 25px;
}

@font-face {
  font-family: 'GoodVibesPro';
  src: url('../static/fonts/GoodVibesPro-Regular.eot');
  src: url('../static/fonts/GoodVibesPro-Regular.eot?#iefix') format('embedded-opentype'), url('../static/fonts/GoodVibesPro-Regular.woff2') format('woff2'), url('../static/fonts/GoodVibesPro-Regular.woff') format('woff'), url('../static/fonts/GoodVibesPro-Regular.ttf') format('truetype'), url('../static/fonts/GoodVibesPro-Regular.svg') format('svg');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Muli';
  src: url('../static/fonts/Muli-Regular.eot');
  src: url('../static/fonts/Muli-Regular.eot?#iefix') format('embedded-opentype'), url('../static/fonts/Muli-Regular.woff2') format('woff2'), url('../static/fonts/Muli-Regular.woff') format('woff'), url('../static/fonts/Muli-Regular.ttf') format('truetype'), url('../static/fonts/Muli-Regular.svg') format('svg');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Muli';
  src: url('../static/fonts/Muli-SemiBold.eot');
  src: url('../static/fonts/Muli-SemiBold.eot?#iefix') format('embedded-opentype'), url('../static/fonts/Muli-SemiBold.woff2') format('woff2'), url('../static/fonts/Muli-SemiBold.woff') format('woff'), url('../static/fonts/Muli-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Muli';
  src: url('../static/fonts/Muli-ExtraBold.eot');
  src: url('../static/fonts/Muli-ExtraBold.eot?#iefix') format('embedded-opentype'), url('../static/fonts/Muli-ExtraBold.woff2') format('woff2'), url('../static/fonts/Muli-ExtraBold.woff') format('woff'), url('../static/fonts/Muli-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

.preloader {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background: #262525;
  z-index: 1001;
}
.preloader__image {
  position: relative;
  top: 50%;
  left: 50%;
  width: 140px;
  height: 140px;
  margin-top: -70px;
  margin-left: -70px;
  text-align: center;
  animation: preloader-rotate 2s infinite linear;
}
@keyframes preloader-rotate {
  0% {
    -webkit-transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.3);
  }
  100% {
    -webkit-transform: scale(1);
  }
}
.loaded_hiding .preloader {
  transition: 0.3s opacity;
  opacity: 0;
}
.loaded .preloader {
  display: none;
}
.section {
  position: relative;
  padding: 70px 0;
}
.section .grid {
  z-index: 10;
  position: relative;
}
.section-page {
  padding-top: 87px;
}
.section.cover {
  padding: 87px 0 20px;
}
.section-content {
  padding-top: 50px;
}
@media screen and (max-width: 767px) {
  .section-content {
    padding-top: 10px;
  }
}
.section p,
.section h1,
.section h2,
.section h4,
.section b,
.section strong,
.section ul,
.section li {
  line-height: 1.3;
  color: #4E4E4E;
}
.section h1,
.section h2,
.section h3,
.section h4 {
  font-family: 'GoodVibesPro', sans-serif;
}
.section h2 {
  font-size: 50px;
  font-weight: 400;
  margin: 0 0 15px;
  padding: 0;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.08);
}
@media screen and (max-width: 991px) {
  .section h2 {
    font-size: 36px;
  }
}
@media screen and (max-width: 767px) {
  .section h2 {
    font-size: 42px;
  }
}
.section p {
  font-size: 15px;
  letter-spacing: .35px;
}
@media screen and (max-width: 991px) {
  .section p {
    font-size: 14px;
  }
}
.section b,
.section strong {
  font-weight: 800;
}
.section-meta {
  text-align: center;
  position: relative;
  padding-bottom: 47px;
}
.section-meta:after {
  position: absolute;
  content: ' ';
  left: calc(50% - 87px);
  bottom: 25px;
  background-size: 177px 32px;
  width: 177px;
  height: 32px;
  background-image: url('../static/images/decor-2.svg');
  background-repeat: no-repeat;
}
.section-meta._light h2 {
  color: #fff;
}
.section-meta._light:after {
  background-image: url('../static/images/decor.svg');
}
.section-btn {
  padding-top: 35px;
  text-align: center;
}
.section-wave {
  position: relative;
}
.section-wave:after,
.section-wave:before {
  position: absolute;
  content: ' ';
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 0;
  left: 0;
  right: 0;
  z-index: 10;
}
.section-wave._bottom:before {
  background-image: url('../static/images/bottom.svg');
  height: 80px;
  bottom: 0;
}
@media screen and (max-width: 991px) {
  .section-wave._bottom:before {
    height: 50px;
  }
}
@media screen and (max-width: 767px) {
  .section-wave._bottom:before {
    height: 30px;
  }
}
.section-wave._top:after {
  background-image: url('../static/images/top.svg');
  height: 80px;
  top: 0;
}
@media screen and (max-width: 991px) {
  .section-wave._top:after {
    height: 50px;
  }
}
@media screen and (max-width: 767px) {
  .section-wave._top:after {
    height: 30px;
  }
}
.section-bg {
  background: #6C645B;
  position: absolute;
  content: ' ';
  z-index: 9;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  opacity: .8;
}
.section-decor {
  text-align: center;
  margin: 0 auto;
  margin-bottom: 30px;
  padding-top: 32px;
  position: relative;
  z-index: 10;
}
.section-decor:after {
  position: absolute;
  content: ' ';
  left: calc(50% - 87px);
  top: 0;
  background-size: 177px 32px;
  width: 177px;
  height: 32px;
  background-image: url('../static/images/decor.svg');
  background-repeat: no-repeat;
}
.section-price .price-item {
  margin-bottom: 30px;
}
.section-BG {
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
@media screen and (max-width: 767px) {
  .section-BG {
    background-attachment: scroll;
  }
}
.section-desc {
  text-align: center;
  max-width: 900px;
  margin: 0 auto;
  padding: 0 15px;
  margin-bottom: 35px;
}
.section-desc p {
  color: #fff;
  font-size: 16px;
  display: block;
}
@media screen and (max-width: 991px) {
  .section-desc p {
    font-size: 14px;
  }
}
.section-infoInner {
  padding: 2px;
  border-radius: 30px;
  background: linear-gradient(-135deg, #9E7337 0%, #F7ED93 51%, #D2B148 100%);
  max-width: 240px;
  margin: 0 auto;
  margin-bottom: 25px;
}
.section-info {
  border-radius: 30px;
  background: #6C645B;
  padding: 5px 15px;
  text-align: center;
}
.section-info p {
  font-size: 16px;
  display: inline-block;
  font-weight: 700;
  color: #fff;
}
.about {
  padding-top: 0;
}
.about-grid {
  padding-top: 60px;
}
.about-item {
  text-align: center;
  margin-top: 15px;
}
.about-item:hover img {
  transform: scale(1.1);
}
.about img {
  width: 100%;
  max-width: 80px;
  transition: .3s ease;
}
.about-desc {
  max-width: 215px;
  margin: 0 auto;
  margin-top: 15px;
}
@media screen and (max-width: 767px) {
  .about-desc {
    font-size: 14px;
  }
}
.photo {
  padding-bottom: 0;
}
.photo-item {
  background: #fff;
  padding: 10px;
  margin: 0 0 30px;
  text-align: center;
  transition: .3s ease;
}
@media screen and (max-width: 767px) {
  .photo-item {
    padding: 5px 5px 10px 5px;
  }
}
.photo-item:hover {
  box-shadow: 0 20px 30px -15px #D6B850;
  transform: scale(1.05);
}
.photo-img {
  width: 100%;
  padding-bottom: 180px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.photo h3 {
  margin-top: 10px;
  font-size: 18px;
  font-family: 'Muli', sans-serif;
  font-weight: 400;
  letter-spacing: 2.4px;
  line-height: 1;
  display: inline-block;
  color: #262525;
}
@media screen and (max-width: 767px) {
  .photo h3 {
    font-size: 14px;
    letter-spacing: 1.4px;
  }
}
.photo .section-btn {
  position: relative;
  z-index: 12;
  padding-top: 0;
  margin-bottom: -23px;
}
.price {
  padding-bottom: 30px;
}
.price-grid {
  margin-bottom: 40px;
}
.price-item._album:hover .price-img {
  box-shadow: 0 20px 30px -10px #D6B850;
}
.price-item._photo:hover .price-img {
  box-shadow: 0 20px 30px -10px #D6B850;
}
.price-item._photo:hover .price-img:after {
  opacity: .7;
}
.price-item._photo:hover img {
  filter: grayscale(100%);
}
.price-item._photo:hover .zoom {
  transform: scale(1);
}
.price-item .zoom {
  position: absolute;
  content: ' ';
  left: calc(50% - 20px);
  top: calc(50% - 21.5px);
  width: 40px;
  height: 43px;
  background-size: 40px 43px;
  background-image: url('../static/images/icon/zoom.svg');
  background-repeat: no-repeat;
  transform: scale(0);
  transition: .4s ease;
}
.price-img {
  border-radius: 10px;
  padding: 3px;
  background: linear-gradient(-135deg, #9E7337 0%, #F7ED93 51%, #D2B148 100%);
  box-shadow: 0 15px 20px 10px rgba(0, 0, 0, 0.15);
  line-height: 0;
  transition: .5s ease;
  position: relative;
}
.price-img:after {
  position: absolute;
  content: ' ';
  left: 3px;
  right: 3px;
  top: 3px;
  bottom: 3px;
  background: #262525;
  border-radius: 10px;
  opacity: 0;
  transition: .3s ease;
}
.price-img img {
  border-radius: 10px;
  width: 100%;
  transition: .4s ease;
}
.price-meta {
  padding: 18px 0;
}
.price-meta ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.price-meta li {
  position: relative;
  font-size: 15px;
  line-height: 1;
  margin-bottom: 15px;
  padding-left: 20px;
}
.price-meta li:last-child {
  margin: 0;
}
.price-meta li:after {
  position: absolute;
  content: ' ';
  left: 0;
  top: calc(50% - 5px);
  width: 9px;
  height: 9px;
  background-size: 9px 9px;
  background-repeat: no-repeat;
  background-image: url('../static/images/icon/li.svg');
}
.price-meta p {
  padding-top: 18px;
  font-size: 16px;
}
.price-title {
  font-family: 'Muli', sans-serif !important;
  font-size: 24px;
  text-transform: uppercase;
  position: relative;
  padding-left: 40px;
  color: #6C645B;
}
@media screen and (max-width: 767px) {
  .price-title {
    margin-top: 15px;
  }
}
.price-title:after {
  position: absolute;
  content: ' ';
  top: calc(50% - 13px);
  left: 0;
  width: 29px;
  height: 25px;
  background-size: 29px 25px;
  background-repeat: no-repeat;
}
.price-title._photo:after {
  background-image: url('../static/images/icon/photo.svg');
}
.price-title._video:after {
  background-image: url('../static/images/icon/video.svg');
}
.price-title._album:after {
  background-image: url('../static/images/icon/album.svg');
}
.video {
  padding-bottom: 0;
}
.video .section-decor {
  margin: 25px 0 0;
}
.video .section-btn {
  position: relative;
  z-index: 12;
  bottom: -21px;
  padding-top: 25px;
}
.reviews {
  padding-bottom: 0;
}
.reviews .owl-item.active .reviews-itemInner {
  filter: grayscale(0);
  opacity: 1;
}
.reviews .owl-item.center .reviews-itemInner {
  box-shadow: 0 8px 10px 0 rgba(214, 184, 80, 0.7);
}
.reviews .section-decor {
  margin-top: 25px;
}
.reviews-itemInner {
  box-shadow: 0 8px 10px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  transition: .4s ease;
  filter: grayscale(100%);
  opacity: .4;
}
.reviews-itemInner:hover {
  box-shadow: 0 8px 10px 0 rgba(214, 184, 80, 0.7);
}
.reviews-item {
  margin-bottom: 25px;
}
.reviews-bg {
  position: absolute;
  content: ' ';
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: linear-gradient(-135deg, #9E7337 0%, #F7ED93 51%, #D2B148 100%);
  opacity: 0;
  z-index: -1;
  border-radius: 10px;
  transition: .9s ease;
}
.reviews img {
  width: 100%;
  max-width: 263px;
  border-radius: 10px;
}
.album {
  padding-bottom: 70px;
}
.album .section-btn {
  padding-top: 0;
}
@media screen and (max-width: 991px) {
  .album .price-item {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 991px) {
  .album .price-title {
    margin-top: 0;
  }
}
@media screen and (max-width: 991px) {
  .contact {
    background-position: top left;
  }
}

.fr-window,
.fr-window [class^="fr-"],
.fr-overlay,
.fr-overlay [class^="fr-"],
.fr-spinner,
.fr-spinner [class^="fr-"] {
  box-sizing: border-box;
}
.fr-window {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  font: 13px/20px "Lucida Sans", "Lucida Sans Unicode", "Lucida Grande", Verdana, Arial, sans-serif;
  transform: translateZ(0px);
}
.fr-overlay {
  z-index: 99998;
}
.fr-window {
  z-index: 99999;
}
.fr-spinner {
  z-index: 100000;
}
.fr-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.fr-overlay-background {
  float: left;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.fr-overlay-ui-fullclick .fr-overlay-background {
  background: #292929;
  opacity: 1;
  filter: alpha(opacity=100);
}
.fr-window.fr-mobile-touch,
.fr-overlay.fr-mobile-touch {
  position: absolute;
  overflow: visible;
}
.fr-measured {
  margin: 0 !important;
  min-width: 0 !important;
  min-height: 0 !important;
}
.fr-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.fr-pages {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.fr-page {
  position: absolute;
  width: 100%;
  height: 100%;
}
.fr-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
  text-align: center;
}
.fr-hovering-clickable .fr-container {
  cursor: pointer;
}
.fr-ui-inside .fr-container {
  padding: 20px 20px;
}
.fr-ui-inside.fr-no-sides .fr-container {
  padding: 20px;
}
.fr-ui-outside .fr-container {
  padding: 20px 82px;
}
.fr-ui-outside.fr-no-sides .fr-container {
  padding: 20px 48px;
}
@media all and (max-width: 700px) and (orientation: portrait), all and (orientation: landscape) and (max-height: 700px) {
  .fr-ui-inside .fr-container {
    padding: 12px 12px;
  }
  .fr-ui-inside.fr-no-sides .fr-container {
    padding: 12px;
  }
  .fr-ui-outside .fr-container {
    padding: 12px 72px;
  }
  .fr-ui-outside.fr-no-sides .fr-container {
    padding: 12px 48px;
  }
}
@media all and (max-width: 500px) and (orientation: portrait), all and (orientation: landscape) and (max-height: 500px) {
  .fr-ui-inside .fr-container {
    padding: 0px 0px;
  }
  .fr-ui-inside.fr-no-sides .fr-container {
    padding: 0px;
  }
  .fr-ui-outside .fr-container {
    padding: 0px 72px;
  }
  .fr-ui-outside.fr-no-sides .fr-container {
    padding: 0px 48px;
  }
}
.fr-ui-fullclick .fr-container {
  padding: 0;
}
.fr-ui-fullclick.fr-no-sides .fr-container {
  padding: 0;
}
.fr-ui-fullclick.fr-type-video .fr-container {
  padding: 0px 62px;
}
.fr-ui-fullclick.fr-no-sides.fr-type-video .fr-container {
  padding: 48px 0px;
}
.fr-overflow-y .fr-container {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.fr-content,
.fr-content-background {
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: visible;
}
.fr-content-element {
  float: left;
  width: 100%;
  height: 100%;
}
.fr-content-background {
  background: #101010;
}
.fr-info {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: #efefef;
  font-size: 13px;
  line-height: 20px;
  text-align: left;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
.fr-info-background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #000;
  line-height: 1%;
  filter: none;
  opacity: 1;
}
.fr-ui-inside .fr-info-background {
  filter: alpha(opacity=80);
  opacity: 0.8;
  zoom: 1;
}
.fr-ui-outside .fr-info-background {
  background: #0d0d0d;
}
.fr-content .fr-info {
  top: auto;
  bottom: 0;
}
.fr-info-padder {
  display: block;
  overflow: hidden;
  padding: 12px;
  position: relative;
  width: auto;
}
.fr-caption {
  width: auto;
  display: inline;
}
.fr-position {
  color: #b3b3b3;
  float: right;
  line-height: 21px;
  opacity: 0.99;
  position: relative;
  text-align: right;
  margin-left: 15px;
  white-space: nowrap;
}
.fr-position-outside,
.fr-position-inside {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 12px;
  width: auto;
  padding: 2px 8px;
  border-radius: 10px;
  font-size: 11px;
  line-height: 20px;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
  display: none;
  overflow: hidden;
  white-space: nowrap;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
.fr-position-inside {
  border: 0;
}
.fr-position-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0d0d0d;
  filter: alpha(opacity=80);
  opacity: 0.8;
  zoom: 1;
}
.fr-position-text {
  color: #b3b3b3;
}
.fr-position-outside .fr-position-text,
.fr-position-inside .fr-position-text {
  float: left;
  position: relative;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  opacity: 1;
}
.fr-ui-outside .fr-position-outside {
  display: block;
}
.fr-ui-outside .fr-info .fr-position {
  display: none;
}
.fr-ui-inside.fr-no-caption .fr-position-inside {
  display: block;
}
.fr-info a,
.fr-info a:hover {
  color: #ccc;
  border: 0;
  background: none;
  text-decoration: underline;
}
.fr-info a:hover {
  color: #eee;
}
.fr-ui-outside.fr-no-caption .fr-info {
  display: none;
}
.fr-ui-inside.fr-no-caption .fr-caption {
  display: none;
}
.fr-stroke {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
}
.fr-stroke-vertical {
  width: 1px;
  height: 100%;
}
.fr-stroke-horizontal {
  padding: 0 1px;
}
.fr-stroke-right {
  left: auto;
  right: 0;
}
.fr-stroke-bottom {
  top: auto;
  bottom: 0;
}
.fr-stroke-color {
  float: left;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.08);
}
.fr-info .fr-stroke-color {
  background: rgba(80, 80, 80, 0.3);
}
.fr-ui-outside.fr-has-caption .fr-content .fr-stroke-bottom {
  display: none;
}
.fr-ui-fullclick .fr-stroke {
  display: none;
}
.fr-ui-fullclick .fr-content-background {
  box-shadow: none;
}
.fr-info .fr-stroke-top {
  display: none;
}
.fr-side {
  position: absolute;
  top: 50%;
  width: 54px;
  height: 72px;
  margin: 0 9px;
  margin-top: -36px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  zoom: 1;
}
.fr-side-previous {
  left: 0;
}
.fr-side-next {
  right: 0;
  left: auto;
}
.fr-side-disabled {
  cursor: default;
}
.fr-side-hidden {
  display: none !important;
}
.fr-side-button {
  float: left;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.fr-side-button-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #101010;
}
.fr-side-button-icon {
  float: left;
  position: relative;
  height: 100%;
  width: 100%;
  zoom: 1;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
@media all and (max-width: 500px) and (orientation: portrait), all and (orientation: landscape) and (max-height: 414px) {
  .fr-side {
    width: 54px;
    height: 60px;
    margin: 0;
    margin-top: -30px;
  }
  .fr-side-button {
    width: 48px;
    height: 60px;
    margin: 0 3px;
  }
}
.fr-close {
  position: absolute;
  width: 48px;
  height: 48px;
  top: 0;
  right: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.fr-close-background,
.fr-close-icon {
  position: absolute;
  top: 12px;
  left: 12px;
  height: 26px;
  width: 26px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.fr-close-background {
  background-color: #000;
}
.fr-thumbnails {
  position: absolute;
  overflow: hidden;
}
.fr-thumbnails-disabled .fr-thumbnails {
  display: none !important;
}
.fr-thumbnails-horizontal .fr-thumbnails {
  width: 100%;
  height: 12%;
  min-height: 74px;
  max-height: 160px;
  bottom: 0;
}
.fr-thumbnails-vertical .fr-thumbnails {
  height: 100%;
  width: 10%;
  min-width: 74px;
  max-width: 160px;
  left: 0;
}
.fr-thumbnails,
.fr-thumbnails * {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.fr-thumbnails-wrapper {
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
}
.fr-thumbnails-vertical .fr-thumbnails-wrapper {
  top: 50%;
  left: 0;
}
.fr-thumbnails-slider {
  position: relative;
  width: 100%;
  height: 100%;
  float: left;
  zoom: 1;
}
.fr-thumbnails-slider-slide {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
.fr-thumbnails-thumbs {
  float: left;
  height: 100%;
  overflow: hidden;
  position: relative;
  top: 0;
  left: 0;
}
.fr-thumbnails-slide {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}
.fr-thumbnail-frame {
  position: absolute;
  zoom: 1;
  overflow: hidden;
}
.fr-thumbnail {
  position: absolute;
  width: 30px;
  height: 100%;
  left: 50%;
  top: 50%;
  zoom: 1;
  cursor: pointer;
  margin: 0 10px;
}
.fr-ltIE9 .fr-thumbnail * {
  overflow: hidden;
  z-index: 1;
  zoom: 1;
}
.fr-thumbnail-wrapper {
  position: relative;
  background: #161616;
  width: 100%;
  height: 100%;
  float: left;
  overflow: hidden;
  display: inline;
  z-index: 0;
}
.fr-thumbnail-overlay {
  cursor: pointer;
}
.fr-thumbnail-active .fr-thumbnail-overlay {
  cursor: default;
}
.fr-thumbnail-overlay,
.fr-thumbnail-overlay-background,
.fr-thumbnail-overlay-border {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-width: 0;
  overflow: hidden;
  border-style: solid;
  border-color: transparent;
}
.fr-ltIE9 .fr-thumbnail-overlay-border {
  border-width: 0 !important;
}
.fr-thumbnail .fr-thumbnail-image {
  position: absolute;
  filter: alpha(opacity=85);
  opacity: 0.85;
  max-width: none;
}
.fr-thumbnail:hover .fr-thumbnail-image,
.fr-thumbnail-active:hover .fr-thumbnail-image {
  filter: alpha(opacity=99);
  opacity: 0.99;
}
.fr-thumbnail-active .fr-thumbnail-image,
.fr-thumbnail-active:hover .fr-thumbnail-image {
  filter: alpha(opacity=35);
  opacity: 0.35;
}
.fr-thumbnail-active {
  cursor: default;
}
.fr-thumbnail-loading,
.fr-thumbnail-loading-background,
.fr-thumbnail-loading-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.fr-thumbnail-loading-background {
  background-color: #161616;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  opacity: 0.8;
  position: relative;
  float: left;
}
.fr-thumbnail-loading-icon {
  display: none;
}
.fr-thumbnail-error .fr-thumbnail-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #202020;
}
.fr-thumbnails-side {
  float: left;
  height: 100%;
  width: 28px;
  margin: 0 5px;
  position: relative;
  overflow: hidden;
}
.fr-thumbnails-side-previous {
  margin-left: 12px;
}
.fr-thumbnails-side-next {
  margin-right: 12px;
}
.fr-thumbnails-vertical .fr-thumbnails-side {
  height: 28px;
  width: 100%;
  margin: 10px 0;
}
.fr-thumbnails-vertical .fr-thumbnails-side-previous {
  margin-top: 20px;
}
.fr-thumbnails-vertical .fr-thumbnails-side-next {
  margin-bottom: 20px;
}
.fr-thumbnails-side-button {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -14px;
  margin-left: -14px;
  width: 28px;
  height: 28px;
  cursor: pointer;
}
.fr-thumbnails-side-button-background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  filter: alpha(opacity=80);
  opacity: 0.8;
  transition: background-color 0.2s ease-in;
  background-color: #333;
  cursor: pointer;
  border-radius: 4px;
}
.fr-thumbnails-side-button:hover .fr-thumbnails-side-button-background {
  background-color: #3b3b3b;
}
.fr-thumbnails-side-button-disabled * {
  cursor: default;
}
.fr-thumbnails-side-button-disabled:hover .fr-thumbnails-side-button-background {
  background-color: #333;
}
.fr-thumbnails-side-button-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.fr-thumbnails-vertical .fr-thumbnails-side,
.fr-thumbnails-vertical .fr-thumbnails-thumbs,
.fr-thumbnails-vertical .fr-thumbnail-frame {
  clear: both;
}
.fr-window-ui-fullclick .fr-thumbnails {
  background: #090909;
}
.fr-window-ui-fullclick.fr-thumbnails-enabled.fr-thumbnails-horizontal .fr-info .fr-stroke-bottom {
  display: block !important;
}
.fr-window-ui-fullclick.fr-thumbnails-enabled.fr-thumbnails-vertical .fr-info .fr-stroke-left {
  display: block !important;
}
.fr-thumbnails-horizontal .fr-thumbnails-thumbs {
  padding: 12px 5px;
}
.fr-thumbnails-vertical .fr-thumbnails-thumbs {
  padding: 5px 12px;
}
.fr-thumbnails-measured .fr-thumbnails-thumbs {
  padding: 0 !important;
}
@media all and (min-height: 700px) {
  .fr-thumbnails-horizontal .fr-thumbnails-thumbs {
    padding: 16px 8px;
  }
  .fr-thumbnails-horizontal .fr-thumbnails-side {
    margin: 0 8px;
  }
  .fr-thumbnails-horizontal .fr-thumbnails-side-previous {
    margin-left: 16px;
  }
  .fr-thumbnails-horizontal .fr-thumbnails-side-next {
    margin-right: 16px;
  }
}
@media all and (min-height: 980px) {
  .fr-thumbnails-horizontal .fr-thumbnails-thumbs {
    padding: 20px 10px;
  }
  .fr-thumbnails-horizontal .fr-thumbnails-side {
    margin: 0 10px;
  }
  .fr-thumbnails-horizontal .fr-thumbnails-side-previous {
    margin-left: 20px;
  }
  .fr-thumbnails-horizontal .fr-thumbnails-side-next {
    margin-right: 20px;
  }
}
@media all and (min-width: 1200px) {
  .fr-thumbnails-vertical .fr-thumbnails-thumbs {
    padding: 8px 16px;
  }
  .fr-thumbnails-vertical .fr-thumbnails-side {
    margin: 0 8px;
  }
  .fr-thumbnails-vertical .fr-thumbnails-side-previous {
    margin-top: 16px;
  }
  .fr-thumbnails-vertical .fr-thumbnails-side-next {
    margin-bottom: 16px;
  }
}
@media all and (min-width: 1800px) {
  .fr-thumbnails-vertical .fr-thumbnails-thumbs {
    padding: 10px 20px;
  }
  .fr-thumbnails-vertical .fr-thumbnails-side {
    margin: 10px 0;
  }
  .fr-thumbnails-vertical .fr-thumbnails-side-previous {
    margin-top: 20px;
  }
  .fr-thumbnails-vertical .fr-thumbnails-side-next {
    margin-bottom: 20px;
  }
}
@media all and (max-width: 500px) and (orientation: portrait), all and (orientation: landscape) and (max-height: 500px) {
  .fr-thumbnails-horizontal .fr-thumbnails {
    display: none !important;
  }
}
@media all and (max-width: 700px) and (orientation: portrait), all and (orientation: landscape) and (max-height: 414px) {
  .fr-thumbnails-vertical .fr-thumbnails {
    display: none !important;
  }
}
@media all and (max-width: 500px) and (orientation: portrait), all and (orientation: landscape) and (max-height: 414px) {
  .fr-page {
    min-width: 100%;
  }
  .fr-page {
    min-height: 100%;
  }
}
.fr-window-ui-fullclick .fr-side-next-outside,
.fr-window-ui-fullclick .fr-side-previous-outside,
.fr-window-ui-fullclick .fr-close-outside,
.fr-window-ui-fullclick .fr-content .fr-side-next,
.fr-window-ui-fullclick .fr-content .fr-side-previous,
.fr-window-ui-fullclick .fr-content .fr-close,
.fr-window-ui-fullclick .fr-content .fr-info,
.fr-window-ui-outside .fr-side-next-fullclick,
.fr-window-ui-outside .fr-side-previous-fullclick,
.fr-window-ui-outside .fr-close-fullclick,
.fr-window-ui-outside .fr-content .fr-side-next,
.fr-window-ui-outside .fr-content .fr-side-previous,
.fr-window-ui-outside .fr-content .fr-close,
.fr-window-ui-outside .fr-content .fr-info,
.fr-window-ui-inside .fr-page > .fr-info,
.fr-window-ui-inside .fr-side-next-fullclick,
.fr-window-ui-inside .fr-side-previous-fullclick,
.fr-window-ui-inside .fr-close-fullclick,
.fr-window-ui-inside .fr-side-next-outside,
.fr-window-ui-inside .fr-side-previous-outside,
.fr-window-ui-inside .fr-close-outside {
  display: none !important;
}
.fr-toggle-ui {
  opacity: 0;
  transition: opacity 0.3s;
}
.fr-visible-fullclick-ui .fr-box > .fr-toggle-ui,
.fr-visible-inside-ui .fr-ui-inside .fr-toggle-ui {
  opacity: 1;
}
.fr-hidden-fullclick-ui .fr-box > .fr-toggle-ui,
.fr-hidden-inside-ui .fr-ui-inside .fr-toggle-ui {
  transition: opacity 0.3s;
}
.fr-ltIE9.fr-hidden-fullclick-ui .fr-box > .fr-toggle-ui,
.fr-ltIE9.fr-hidden-inside-ui .fr-ui-inside .fr-toggle-ui {
  display: none;
}
.fr-spinner {
  position: fixed;
  width: 52px;
  height: 52px;
  background: #101010;
  background: rgba(16, 16, 16, 0.85);
  border-radius: 5px;
}
.fr-spinner div {
  position: absolute;
  top: 0;
  left: 0;
  height: 64%;
  width: 64%;
  margin-left: 18%;
  margin-top: 18%;
  opacity: 1;
  animation: fresco-12 1.2s infinite ease-in-out;
}
.fr-spinner div:after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 2px;
  height: 8px;
  margin-left: -1px;
  background: #fff;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.fr-spinner div.fr-spin-1 {
  transform: rotate(30deg);
  animation-delay: -1.1s;
}
.fr-spinner div.fr-spin-2 {
  transform: rotate(60deg);
  animation-delay: -1s;
}
.fr-spinner div.fr-spin-3 {
  transform: rotate(90deg);
  animation-delay: -0.9s;
}
.fr-spinner div.fr-spin-4 {
  transform: rotate(120deg);
  animation-delay: -0.8s;
}
.fr-spinner div.fr-spin-5 {
  transform: rotate(150deg);
  animation-delay: -0.7s;
}
.fr-spinner div.fr-spin-6 {
  transform: rotate(180deg);
  animation-delay: -0.6s;
}
.fr-spinner div.fr-spin-6 {
  transform: rotate(180deg);
  animation-delay: -0.6s;
}
.fr-spinner div.fr-spin-7 {
  transform: rotate(210deg);
  animation-delay: -0.5s;
}
.fr-spinner div.fr-spin-8 {
  transform: rotate(240deg);
  animation-delay: -0.4s;
}
.fr-spinner div.fr-spin-9 {
  transform: rotate(270deg);
  animation-delay: -0.3s;
}
.fr-spinner div.fr-spin-10 {
  transform: rotate(300deg);
  animation-delay: -0.2s;
}
.fr-spinner div.fr-spin-11 {
  transform: rotate(330deg);
  animation-delay: -0.1s;
}
.fr-spinner div.fr-spin-12 {
  transform: rotate(360deg);
  animation-delay: 0s;
}
@keyframes fresco-12 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.fr-thumbnail-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -20px;
  width: 40px;
  height: 40px;
}
.fr-thumbnail-spinner-spin {
  position: relative;
  float: left;
  margin: 8px 0 0 8px;
  text-indent: -9999em;
  border-top: 2px solid rgba(255, 255, 255, 0.2);
  border-right: 2px solid rgba(255, 255, 255, 0.2);
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  border-left: 2px solid #fff;
  animation: fr-thumbnail-spin 1.1s infinite linear;
}
.fr-thumbnail-spinner-spin,
.fr-thumbnail-spinner-spin:after {
  border-radius: 50%;
  width: 24px;
  height: 24px;
}
@keyframes fr-thumbnail-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.fr-error {
  float: left;
  position: relative;
  background-color: #ca3434;
  width: 160px;
  height: 160px;
}
.fr-error-icon {
  position: absolute;
  width: 42px;
  height: 42px;
  top: 50%;
  left: 50%;
  margin-left: -21px;
  margin-top: -21px;
}
.fr-window-skin-fresco .fr-side-button-icon,
.fr-window-skin-fresco .fr-close-icon,
.fr-window-skin-fresco .fr-thumbnails-side-button-icon,
.fr-window-skin-fresco .fr-error-icon {
  background-image: url("images/fresco/sprite.svg");
}
.fr-window-skin-fresco.fr-no-svg .fr-side-button-icon,
.fr-window-skin-fresco.fr-no-svg .fr-close-icon,
.fr-window-skin-fresco.fr-no-svg .fr-thumbnails-side-button-icon,
.fr-window-skin-fresco .fr-error-icon {
  background-image: url("images/fresco/sprite.png");
}
.fr-window-skin-fresco .fr-error-icon {
  background-position: -160px -126px;
}
.fr-window-skin-fresco .fr-content-background {
  background: #101010;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
}
.fr-window-skin-fresco.fr-window-ui-fullclick .fr-content-background {
  box-shadow: none;
}
.fr-window-skin-fresco .fr-thumbnail-wrapper {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}
.fr-window-skin-fresco .fr-thumbnail-active .fr-thumbnail-wrapper {
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
}
.fr-window-skin-fresco .fr-side-button-background {
  background-color: transparent;
}
.fr-window-skin-fresco .fr-side-previous .fr-side-button-icon {
  background-position: -13px -14px;
}
.fr-window-skin-fresco .fr-side-next .fr-side-button-icon {
  background-position: -93px -14px;
}
.fr-window-skin-fresco .fr-side-previous:hover .fr-side-button-icon {
  background-position: -13px -114px;
}
.fr-window-skin-fresco .fr-side-next:hover .fr-side-button-icon {
  background-position: -93px -114px;
}
.fr-window-skin-fresco.fr-no-svg .fr-hovering-previous .fr-side-previous .fr-side-button-icon,
.fr-window-skin-fresco.fr-no-svg.fr-mobile-touch .fr-side-previous .fr-side-button-icon {
  background-position: -13px -114px;
}
.fr-window-skin-fresco.fr-no-svg .fr-hovering-next .fr-side-next .fr-side-button-icon,
.fr-window-skin-fresco.fr-no-svg.fr-mobile-touch .fr-side-next .fr-side-button-icon {
  background-position: -93px -114px;
}
.fr-window-skin-fresco.fr-no-svg .fr-side-previous.fr-side-disabled .fr-side-button-icon,
.fr-window-skin-fresco.fr-no-svg .fr-hovering-previous .fr-side-previous.fr-side-disabled .fr-side-button-icon,
.fr-window-skin-fresco.fr-no-svg .fr-side-previous.fr-side-disabled:hover .fr-side-button-icon {
  background-position: -13px -214px;
}
.fr-window-skin-fresco.fr-no-svg .fr-side-next.fr-side-disabled .fr-side-button-icon,
.fr-window-skin-fresco.fr-no-svg .fr-hovering-next .fr-side-next.fr-side-disabled .fr-side-button-icon,
.fr-window-skin-fresco.fr-no-svg .fr-side-next.fr-side-disabled:hover .fr-side-button-icon {
  background-position: -93px -214px;
}
.fr-window-skin-fresco.fr-svg .fr-side-previous .fr-side-button-icon {
  background-position: -13px -114px;
}
.fr-window-skin-fresco.fr-svg .fr-side-next .fr-side-button-icon {
  background-position: -93px -114px;
}
.fr-window-skin-fresco.fr-svg .fr-side-button-icon {
  opacity: 0.5;
}
.fr-window-skin-fresco.fr-svg .fr-side:hover .fr-side-button-icon,
.fr-window-skin-fresco.fr-svg .fr-hovering-previous .fr-side-previous .fr-side-button-icon,
.fr-window-skin-fresco.fr-svg .fr-hovering-next .fr-side-next .fr-side-button-icon {
  opacity: 1;
}
.fr-window-skin-fresco.fr-svg.fr-mobile-touch .fr-side .fr-side-button-icon {
  opacity: 0.8;
}
.fr-window-skin-fresco.fr-svg .fr-side-disabled .fr-side-button-icon,
.fr-window-skin-fresco.fr-svg .fr-hovering-previous .fr-side-disabled .fr-side-button-icon,
.fr-window-skin-fresco.fr-svg .fr-hovering-next .fr-side-disabled .fr-side-button-icon,
.fr-window-skin-fresco.fr-svg .fr-side-disabled:hover .fr-side-button-icon,
.fr-window-skin-fresco.fr-svg.fr-mobile-touch .fr-side-disabled .fr-side-button-icon {
  opacity: 0.2;
}
.fr-window-skin-fresco.fr-window-ui-inside .fr-type-image .fr-side-disabled .fr-side-button-icon,
.fr-window-skin-fresco.fr-window-ui-fullclick.fr-showing-type-image .fr-side-disabled .fr-side-button-icon {
  background-image: none;
}
@media all and (max-width: 500px) and (orientation: portrait), all and (orientation: landscape) and (max-height: 414px) {
  .fr-window-skin-fresco .fr-side-previous .fr-side-button-icon {
    background-position: 0px -300px;
  }
  .fr-window-skin-fresco .fr-side-next .fr-side-button-icon {
    background-position: -48px -300px;
  }
  .fr-window-skin-fresco .fr-side-previous:hover .fr-side-button-icon {
    background-position: 0px -360px;
  }
  .fr-window-skin-fresco .fr-side-next:hover .fr-side-button-icon {
    background-position: -48px -360px;
  }
  .fr-window-skin-fresco.fr-no-svg .fr-hovering-previous .fr-side-previous .fr-side-button-icon,
  .fr-window-skin-fresco.fr-no-svg.fr-mobile-touch .fr-side-previous .fr-side-button-icon {
    background-position: 0px -360px;
  }
  .fr-window-skin-fresco.fr-no-svg .fr-hovering-next .fr-side-next .fr-side-button-icon,
  .fr-window-skin-fresco.fr-no-svg.fr-mobile-touch .fr-side-next .fr-side-button-icon {
    background-position: -48px -360px;
  }
  .fr-window-skin-fresco.fr-svg .fr-side-previous .fr-side-button-icon {
    background-position: 0px -360px;
  }
  .fr-window-skin-fresco.fr-svg .fr-side-next .fr-side-button-icon {
    background-position: -48px -360px;
  }
  .fr-window-skin-fresco.fr-no-svg .fr-side-previous.fr-side-disabled .fr-side-button-icon,
  .fr-window-skin-fresco.fr-no-svg .fr-hovering-previous .fr-side-previous.fr-side-disabled .fr-side-button-icon,
  .fr-window-skin-fresco.fr-no-svg .fr-side-previous.fr-side-disabled:hover .fr-side-button-icon {
    background-position: 0px -420px;
  }
  .fr-window-skin-fresco.fr-no-svg .fr-side-next.fr-side-disabled .fr-side-button-icon,
  .fr-window-skin-fresco.fr-no-svg .fr-hovering-next .fr-side-next.fr-side-disabled .fr-side-button-icon,
  .fr-window-skin-fresco.fr-no-svg .fr-side-next.fr-side-disabled:hover .fr-side-button-icon {
    background-position: -48px -420px;
  }
}
.fr-window-skin-fresco.fr-window-ui-outside .fr-close-background {
  background-color: #363636;
}
.fr-window-skin-fresco.fr-window-ui-outside .fr-close:hover .fr-close-background {
  background-color: #434343;
}
.fr-window-skin-fresco.fr-window-ui-inside .fr-close-background,
.fr-window-skin-fresco.fr-window-ui-fullclick .fr-close-background {
  background-color: #131313;
  filter: alpha(opacity=80);
  opacity: 0.8;
}
.fr-window-skin-fresco.fr-window-ui-inside .fr-close:hover .fr-close-background,
.fr-window-skin-fresco.fr-window-ui-fullclick .fr-close:hover .fr-close-background {
  background-color: #191919;
}
.fr-window-skin-fresco .fr-close .fr-close-icon {
  background-position: -168px -8px;
}
.fr-window-skin-fresco .fr-close:hover .fr-close-icon {
  background-position: -210px -8px;
}
.fr-window-skin-fresco.fr-svg .fr-close .fr-close-icon {
  background-position: -210px -8px;
  opacity: 0.8;
}
.fr-window-skin-fresco .fr-close:hover .fr-close-icon {
  opacity: 1;
}
.fr-window-skin-fresco.fr-svg.fr-mobile-touch .fr-close .fr-close-icon,
.fr-window-skin-fresco.fr-mobile-touch .fr-close:hover .fr-close-icon {
  opacity: 1;
}
.fr-window-skin-fresco .fr-thumbnail-wrapper {
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}
.fr-window-skin-fresco .fr-thumbnail-wrapper {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}
.fr-window-skin-fresco .fr-thumbnail-active .fr-thumbnail-wrapper {
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
}
.fr-window-skin-fresco .fr-thumbnail-wrapper {
  box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.3);
}
.fr-window-skin-fresco .fr-thumbnail-overlay-border {
  border-width: 1px;
  border-color: rgba(255, 255, 255, 0.08);
}
.fr-window-skin-fresco .fr-thumbnail-active .fr-thumbnail-overlay-border,
.fr-window-skin-fresco .fr-thumbnail-active:hover .fr-thumbnail-overlay-border {
  border: 0;
}
.fr-window-skin-fresco .fr-thumbnails-side-previous .fr-thumbnails-side-button-icon {
  background-position: -167px -49px;
}
.fr-window-skin-fresco .fr-thumbnails-side-previous:hover .fr-thumbnails-side-button-icon {
  background-position: -209px -49px;
}
.fr-window-skin-fresco .fr-thumbnails-side-next .fr-thumbnails-side-button-icon {
  background-position: -167px -91px;
}
.fr-window-skin-fresco .fr-thumbnails-side-next:hover .fr-thumbnails-side-button-icon {
  background-position: -209px -91px;
}
.fr-window-skin-fresco.fr-thumbnails-vertical .fr-thumbnails-side-previous .fr-thumbnails-side-button-icon {
  background-position: -293px -49px;
}
.fr-window-skin-fresco.fr-thumbnails-vertical .fr-thumbnails-side-previous:hover .fr-thumbnails-side-button-icon {
  background-position: -335px -49px;
}
.fr-window-skin-fresco.fr-thumbnails-vertical .fr-thumbnails-side-next .fr-thumbnails-side-button-icon {
  background-position: -293px -91px;
}
.fr-window-skin-fresco.fr-thumbnails-vertical .fr-thumbnails-side-next:hover .fr-thumbnails-side-button-icon {
  background-position: -335px -91px;
}
.fr-window-skin-fresco.fr-svg .fr-thumbnails-side .fr-thumbnails-side-button-icon {
  transition: opacity 0.2s ease-in;
  opacity: 0.8;
}
.fr-window-skin-fresco.fr-svg .fr-thumbnails-side-previous .fr-thumbnails-side-button-icon,
.fr-window-skin-fresco.fr-svg .fr-thumbnails-side-previous .fr-thumbnails-side-button-disabled {
  background-position: -167px -49px;
}
.fr-window-skin-fresco.fr-svg .fr-thumbnails-side-next .fr-thumbnails-side-button-icon,
.fr-window-skin-fresco.fr-svg .fr-thumbnails-side-next .fr-thumbnails-side-button-disabled {
  background-position: -209px -91px;
}
.fr-window-skin-fresco.fr-svg .fr-thumbnails-side:hover .fr-thumbnails-side-button-icon {
  opacity: 1;
}
.fr-window-skin-fresco.fr-svg.fr-thumbnails-vertical .fr-thumbnails-side-previous .fr-thumbnails-side-button-icon,
.fr-window-skin-fresco.fr-svg.fr-thumbnails-vertical .fr-thumbnails-side-previous .fr-thumbnails-side-button-disabled {
  background-position: -293px -49px;
}
.fr-window-skin-fresco.fr-svg.fr-thumbnails-vertical .fr-thumbnails-side-next .fr-thumbnails-side-button-icon,
.fr-window-skin-fresco.fr-svg.fr-thumbnails-vertical .fr-thumbnails-side-next .fr-thumbnails-side-button-disabled {
  background-position: -335px -91px;
}
.fr-window-skin-fresco.fr-svg .fr-thumbnails-side .fr-thumbnails-side-button-disabled,
.fr-window-skin-fresco.fr-svg .fr-thumbnails-side:hover .fr-thumbnails-side-button-disabled {
  opacity: 0.5;
}
.fr-window-skin-fresco.fr-no-svg .fr-thumbnails-side-previous .fr-thumbnails-side-button-disabled .fr-thumbnails-side-button-icon,
.fr-window-skin-fresco.fr-no-svg .fr-thumbnails-side-previous:hover .fr-thumbnails-side-button-disabled .fr-thumbnails-side-button-icon {
  background-position: -251px -49px;
}
.fr-window-skin-fresco.fr-no-svg .fr-thumbnails-side-next .fr-thumbnails-side-button-disabled .fr-thumbnails-side-button-icon,
.fr-window-skin-fresco.fr-no-svg .fr-thumbnails-side-next:hover .fr-thumbnails-side-button-disabled .fr-thumbnails-side-button-icon {
  background-position: -251px -91px;
}
.fr-window-skin-fresco.fr-no-svg .fr-thumbnails-side .fr-thumbnails-side-button-disabled .fr-thumbnails-side-button-background,
.fr-window-skin-fresco.fr-no-svg .fr-thumbnails-side:hover .fr-thumbnails-side-button-disabled .fr-thumbnails-side-button-background {
  filter: alpha(opacity=50);
}
.fr-window-skin-fresco.fr-no-svg.fr-thumbnails-vertical .fr-thumbnails-side-previous .fr-thumbnails-side-button-disabled .fr-thumbnails-side-button-icon,
.fr-window-skin-fresco.fr-no-svg.fr-thumbnails-vertical .fr-thumbnails-side-previous:hover .fr-thumbnails-side-button-disabled .fr-thumbnails-side-button-icon {
  background-position: -377px -49px;
}
.fr-window-skin-fresco.fr-no-svg.fr-thumbnails-vertical .fr-thumbnails-side-next .fr-thumbnails-side-button-disabled .fr-thumbnails-side-button-icon,
.fr-window-skin-fresco.fr-no-svg.fr-thumbnails-vertical .fr-thumbnails-side-next:hover .fr-thumbnails-side-button-disabled .fr-thumbnails-side-button-icon {
  background-position: -377px -91px;
}
@media screen and (max-width: 767px) {
  .fr-ui-fullclick.fr-type-video .fr-container {
    padding: 0 10px;
  }
}

.video {
  text-decoration: none;
  position: relative;
  z-index: 9;
}
.video-list {
  margin: 10px 0 35px;
  text-align: center;
}
.video-list li {
  margin-right: 15px;
}
.video-list li:last-child {
  margin-right: 0;
}
@media screen and (max-width: 991px) {
  .video-list li {
    margin-bottom: 10px;
  }
}
.video-list a {
  font-size: 18px;
  color: #fff;
  line-height: 1;
  font-weight: 400;
}
.video-list a:hover {
  color: #D6B850;
  cursor: pointer;
}
.video-list a.current {
  color: #D6B850;
  opacity: 1;
}
.video-slideInner {
  padding: 6px;
  border-radius: 10px;
  background: linear-gradient(-135deg, #9E7337 0%, #F7ED93 51%, #D2B148 100%);
}
@media screen and (max-width: 991px) {
  .video-slideInner {
    padding: 3px;
  }
}
.video-slideInner._single:hover .video-img {
  opacity: 1;
  transform: scale(1.2);
}
.video-slideInner._single:hover .video-meta {
  transform: translateY(200px);
}
.video-slideInner._single .video-slide {
  box-shadow: 0 8px 10px 0 rgba(214, 184, 80, 0.7);
  width: 100%;
}
.video-slideInner._single .video-img {
  filter: none;
}
.video-slideInner._single .video-meta {
  transform: translateY(0);
}
.video-bg {
  position: absolute;
  content: ' ';
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: linear-gradient(-135deg, #9E7337 0%, #F7ED93 51%, #D2B148 100%);
  opacity: 0;
  z-index: -1;
  border-radius: 10px;
  transition: .9s ease;
}
.video-slide {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  width: 850px;
  max-width: 90vw;
  height: 450px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 10px;
  z-index: 9;
  box-shadow: 0 15px 20px 10px rgba(0, 0, 0, 0.15);
}
.video-slide:after {
  position: absolute;
  content: ' ';
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #6C645B;
  z-index: -1;
}
.video-slide:hover .video-img {
  transform: scale(1.1);
}
.video-img {
  background: 50% no-repeat;
  background-size: cover;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  transition: .8s ease;
  filter: grayscale(100%);
  opacity: .55;
}
.video-meta {
  position: absolute;
  content: ' ';
  left: 35px;
  bottom: 40px;
  transition: .7s ease;
  transform: translateY(200px);
}
.video-meta h3 {
  font-size: 20px;
  line-height: 1.3;
  font-weight: 700;
  font-family: 'Muli', sans-serif;
  color: #fff;
  position: relative;
  padding-left: 45px;
  text-transform: uppercase;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
}
.video-meta h3:after {
  position: absolute;
  content: ' ';
  left: 0;
  top: calc(50% - 15px);
  width: 30px;
  height: 30px;
  background-size: 30px 30px;
  background-repeat: no-repeat;
  background-image: url('../static/images/icon/play-sm.svg');
  transition: .4s ease;
}
.video-carousel {
  position: relative;
  transform: translateX(0);
  transition: 1.2s ease;
  z-index: 10;
  overflow-y: hidden;
}
.video-carousel .owl-stage-outer {
  overflow: visible !important;
}
.video-carousel .owl-stage-outer .owl-stage {
  width: 10000em !important;
}
.video-carousel .owl-stage-outer .owl-item {
  transition: all 300ms ease-in-out;
}
.owl-item.current .video-slide {
  box-shadow: 0 25px 30px -15px rgba(233, 215, 119, 0.5);
}
.owl-item.current .video-img {
  filter: none;
  opacity: 1;
}
.owl-item.current .video-meta {
  transform: translateY(0);
}
.owl-item.current .video-bg {
  opacity: 1;
}
.owl-dots {
  text-align: center;
  margin: 15px 0 0;
}
@media screen and (max-width: 991px) {
  .owl-dots {
    display: none;
  }
}
.owl-dots .owl-dot {
  display: inline-block;
  margin-right: 15px;
  width: 40px;
  height: 3px;
  background: #D6B850 !important;
  opacity: 0.3;
  transition: .4s ease;
}
.owl-dots .owl-dot:last-child {
  margin: 0;
}
.owl-dots .owl-dot.active {
  opacity: 1;
}
.owl-nav {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  position: relative;
  top: -250px;
}
.owl-btn {
  background: none;
  border: 0;
  position: absolute;
  transition: .4s ease;
  opacity: .7;
}
@media screen and (max-width: 991px) {
  .owl-btn {
    opacity: 1;
  }
}
.owl-btn.disabled {
  opacity: 0!important;
  cursor: text!important;
}
.owl-btn:first-child {
  left: 0;
}
.owl-btn:last-child {
  right: 0;
}
.owl-btn:hover {
  transform: scale(1.2);
  opacity: 1;
}
.owl-btn:active {
  transform: scale(1.1);
}
.owl-btn .icon-prev,
.owl-btn .icon-next {
  background-size: 40px 40px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: block;
}
.owl-btn .icon-prev {
  background-image: url('../static/images/icon/left.svg');
}
.owl-btn .icon-next {
  background-image: url('../static/images/icon/right.svg');
}
.js-loading {
  transform: translateX(-1000px);
}

.btn {
  background: #6C645B;
  box-shadow: 0 10px 14px -8px #D6B850;
  border-radius: 30px;
  padding: 13px 24px;
  font-size: 16px;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 1.3px;
  text-transform: uppercase;
  transition: .4s ease;
  border: 0;
}
.btn:hover {
  box-shadow: 0 16px 23px -8px #D6B850;
}
.btn .text {
  background: linear-gradient(-141deg, #9E7337 0%, #F7ED93 51%, #D2B148 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.btn-inner {
  padding: 2px;
  background: linear-gradient(-135deg, #9E7337 0%, #F7ED93 51%, #D2B148 100%);
  display: inline-block;
  border-radius: 30px;
  transition: .4s ease;
}
.btn-inner:hover {
  transform: scale(1.1);
}
.btn._sm {
  font-size: 12px;
  padding: 8px 11px;
  box-shadow: 0 20px 15px -12px #D6B850;
}
.btn._sm:hover {
  box-shadow: 0 20px 15px -8px #D6B850;
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(212, 77, 77, 0.8);
  }
  70% {
    box-shadow: 0 0 0 15px rgba(212, 77, 77, 0);
  }
  to {
    box-shadow: 0 0 0 0 rgba(212, 77, 77, 0);
  }
}

.form {
  width: 100%;
  max-width: 400px;
  display: block;
  margin: 0 auto;
  position: relative;
}
.form._sm {
  margin-top: 10px;
}
.form-field {
  width: 100%;
  border: none;
  background: none;
  padding: 10px 28px;
  background: #fff;
  outline: none;
  font-size: 18px;
  line-height: 1;
  color: #262525;
  transition: .4s ease;
  box-shadow: 0 15px 14px -10px rgba(233, 215, 119, 0.3);
  border-radius: 6px;
}
.form-field:hover {
  box-shadow: 0 20px 10px -15px #D6B850;
}
.form-field:active,
.form-field:focus,
.form-field:hover {
  color: #262525;
}
.form-field._area {
  resize: none;
  min-height: 176px;
  line-height: 1.3;
}
@media screen and (max-width: 767px) {
  .form-field._area {
    min-height: 150px;
  }
}
.form-group {
  margin-bottom: 25px;
}
.form-btn {
  margin: 15px 0 15px 0;
  text-align: center;
}
.form-success {
  text-align: center;
  position: absolute;
  border-radius: 10px;
  content: ' ';
  display: none;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 120px 30px 30px;
  background: #fff;
  z-index: 3;
}
.form-success h3 {
  font-size: 40px;
  font-family: 'Muli', sans-serif;
  margin-bottom: 20px;
}
.form-success p {
  margin-top: 15px;
  font-size: 16px;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #262525;
}
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 5px 0 rgba(216, 216, 216, 0.05), inset 0 0 0 50px rgba(216, 216, 216, 0.05);
  -webkit-text-fill-color: #262525;
}

.modal {
  display: none;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  background: rgba(0, 0, 0, 0.3);
}
.modal.fade .modal-dialog {
  transform: translate(0, -25%);
  transition: transform 0.3s ease-out;
}
.modal._in .modal-dialog {
  transform: translate(0, 0);
}
.modal p {
  margin: 0 0 20px;
}
.modal-success {
  margin: 20px 0;
}
.modal-dialog {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  max-width: 555px;
  margin: .4rem auto;
  min-height: calc(100% - 1.6rem);
  overflow: hidden;
  z-index: 999;
}
.modal-box {
  position: relative;
  z-index: 999;
}
.modal-contentInner {
  padding: 3px;
  border-radius: 30px;
  background: linear-gradient(-135deg, #9E7337 0%, #F7ED93 51%, #D2B148 100%);
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 25px 30px -20px rgba(233, 215, 119, 0.4);
  margin: 0 30px;
}
.modal-bg {
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
  position: absolute;
  content: ' ';
  left: 3px;
  right: 3px;
  top: 3px;
  bottom: 3px;
  border-radius: 30px;
}
.modal-bg:after {
  position: absolute;
  content: ' ';
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(108, 100, 91, 0.8);
  border-radius: 30px;
}
.modal-content {
  padding: 30px 30px 50px 30px;
  text-align: center;
  border-radius: 30px;
  position: relative;
}
@media screen and (max-width: 767px) {
  .modal-content {
    margin: 0 15px;
    padding: 30px 15px;
  }
}
.modal-content .section {
  padding: 0;
}
.modal-content .form {
  margin: 0 auto;
  max-width: 360px;
}
.modal-content .modal-title {
  font-family: 'GoodVibesPro', sans-serif;
  font-size: 44px;
  font-weight: 400;
  margin: 0 0 15px;
  padding: 0;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.08);
}
.modal-form {
  z-index: 1050;
  position: relative;
}
.modal-closeWrap {
  padding-bottom: 10px;
  text-align: right;
}
.modal-close,
.fr-close {
  border: none;
  background-color: inherit;
  transition: all .3s ease;
  background-image: url('../static/images/icon/close.svg');
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  background-size: 30px 30px;
  z-index: 4;
}
.modal-close:hover,
.fr-close:hover {
  transform: scale(1.2);
}
.fr-close {
  top: 20px;
  right: 20px;
}
.fr-close:hover {
  transform: scale(1.2);
}
.fr-close-background,
.fr-close-icon {
  display: none;
}
.modal-backdrop {
  position: fixed;
  z-index: 1040;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(108, 100, 91, 0.9);
}
.fr-side-button-background,
.fr-side-button-icon {
  display: none;
}
.fr-side {
  background-size: 40px 40px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  opacity: .7;
}
.fr-side:first-child {
  left: 0;
}
.fr-side:last-child {
  right: 0;
}
.fr-side:hover {
  transform: scale(1.2);
  opacity: 1;
}
.fr-side:active {
  transform: scale(1.1);
}
.fr-side-disabled {
  opacity: 0!important;
  cursor: text!important;
}
.fr-side-previous {
  background-image: url('../static/images/icon/left.svg');
  left: 15px;
}
.fr-side-next {
  background-image: url('../static/images/icon/right.svg');
  right: 15px;
}

.footer {
  padding: 25px 0;
  position: relative;
  z-index: 2;
  background: #fff;
}
@media screen and (max-width: 767px) {
  .footer {
    text-align: center;
  }
}
.footer p,
.footer a {
  font-size: 16px;
  line-height: 1.2;
  margin: 0;
  font-weight: 400;
  color: #262525;
  text-decoration: none;
}
.footer p b,
.footer a b,
.footer p span,
.footer a span {
  font-weight: 700;
}
.footer p span,
.footer a span {
  transition: .4s ease;
  text-decoration: underline;
}
.footer a:hover span {
  color: #D6B850;
}
.footer-dev {
  text-align: right;
}
@media screen and (max-width: 767px) {
  .footer-dev {
    margin-top: 10px;
    text-align: center;
  }
}
