.cover {
  &-mob {
    display: none;
    width: 30px;
    height: 30px;
    border: none;
    background-color: inherit;
    background-image: url('../static/images/icon/nav.svg');
    background-size: 30px 30px;
    background-repeat: no-repeat;
    @media screen and (max-width: @g-screen-sm-max) {
      display: block;
    }
  }
  .menu {
    margin: 0;
  }
  &-header {
    padding: 30px 0;
    z-index: 999;
    position: relative;
    &._fixed {
      position: fixed;
      width: 100%;
      background: none;
      transition: .4s ease;
      @media screen and (max-width: @g-screen-sm-max) {
        background: @g-color-dark;
      }
    }
    &._stick {
      padding: 10px 0;
      background: @g-color-dark;
      box-shadow: 0 2px 5px 0 rgba(41, 41, 38, 0.2);
    }
    @media screen and (max-width: @g-screen-sm-max) {
      padding: 10px 0;
      background: @g-color-dark;
      box-shadow: 0 2px 5px 0 rgba(41, 41, 38, 0.2);
    }
  }
  &-nav {
    text-align: left;
    .menu {
      @media screen and (max-width: @g-screen-sm-max) {
        display: none;
      }
    }
    ul {
      padding: 0;
      margin: 0;
    }
    li {
      display: inline-block;
      margin-right: 15px;
      &:last-child {
        margin: 0;
      }
      &.current-menu-item {
        a {
          color: @g-color;
        }
      }
    }
    a {
      color: @g-color-light;
      text-transform: uppercase;
      font-size: 14px;
      line-height: 1;
      font-weight: 400;
      text-decoration: none;
      &:hover,
      &:focus,
      &:active {
        color: @g-color-accent;
      }
    }
  }

  // SOC HEADER

  &-soc {
    padding: 0;
    text-align: right;
    display: inline-block;
    vertical-align: middle;
    li {
      width: 25px;
      height: 25px;
      margin-right: 30px;
      @media screen and (max-width: @g-screen-sm-max) {
        margin-right: 15px;
      }
      &:last-child {
        margin-right: 60px;
        @media screen and (max-width: @g-screen-sm-max) {
          margin-right: 45px;
        }
      }
    }
    .icon {
      display: block;
      width: 23px;
      height: 23px;
      background-size: 23px 23px;
      background-repeat: no-repeat;
      transition: .4s ease;
      &:hover {
        transform: scale(1.3);
      }
      &._inst {
        background-image: url('../static/images/soc/inst.svg');
      }
      &._tel {
        background-image: url('../static/images/soc/tel.svg');
      }
      &._wh {
        background-image: url('../static/images/soc/wh.svg');
      }
      &._fb {
        background-image: url('../static/images/soc/fb.svg');
      }
    }
  }
  &-right {
    text-align: right;
  }
  &-lang {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    @media screen and (max-width: @g-screen-sm-max) {
      margin-right: 15px;
    }
  }
  &-langList {
    position: absolute;
    content: ' ';
    top: 0;
    left: 0;
    text-align: center;
    padding: 0;
    display: none;
    &:after {
      position: absolute;
      content: ' ';
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: @g-color-dark;
      border-radius: 30px;
      z-index: -1;
      box-shadow: 0 2px 5px 0 rgba(41, 41, 38, 0.2);
    }
    &._in {
      display: block;
    }
    li {
      padding: 1px 0;
      margin: 0 0 10px;
      position: relative;
      &:first-child {
        opacity: 0;
        &:after {
          display: none;
        }
      }
      &:after {
        position: absolute;
        content: ' ';
        left: 0;
        right: 0;
        bottom: 0;
        border-bottom: 1.5px solid @g-color;
      }
    }
    a {
      text-decoration: none;
      font-size: 12px;
      line-height: 20px;
      color: @g-color-light;
      font-weight: 700;
      &:hover {
        color: @g-color;
      }
    }
  }
  &-btn {
    background: none;
    padding: 0;
    width: 27px;
    height: 27px;
    position: relative;
    font-size: 11px;
    line-height: 20px;
    color: @g-color-light;
    font-weight: 700;
    border: 1.5px solid @g-color;
    border-radius: 100%;
    &._in {
      &:after {
        transform: rotate(90deg);
      }
    }
    &:after {
      transition: .3s ease;
      position: absolute;
      content: ' ';
      top: 50%;
      right: -15px;
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid @g-color-light;
    }
  }
  &-mobile {
    background: @g-color-dark;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    transform: translateY(-100%);
    transition: .3s linear;
    box-shadow: 0 2px 5px 0 rgba(41, 41, 38, 0.2);
    &._in {
      transform: translateY(0);
    }
    .menu {
      padding: 60px 0 0 0;
    }
    li a {
      font-size: 20px;
      line-height: 1.3;
      color: @g-color-light;
      text-decoration: none;
      font-weight: 400;
      text-transform: uppercase;
    }
    li {
      margin-bottom: 25px;
    }
  }
}