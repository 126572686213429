.modal {
  display: none;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  background: rgba(0,0,0,.3);
  &.fade { .modal-dialog { transform: translate(0, -25%); transition: transform 0.3s ease-out; } }
  &._in { .modal-dialog { transform: translate(0, 0); } }
  p {
    margin: 0 0 20px;
  }
}

.modal-success {
  margin: 20px 0;
}

.modal-dialog {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 555px;
  margin: .4rem auto;
  min-height: calc(~"100% - 1.6rem");
  overflow: hidden;
  z-index: 999;
}

.modal-box {
  position: relative;
  z-index: 999;
}
.modal-contentInner {
  padding: 3px;
  border-radius: 30px;
  background: @g-color-gradient;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 25px 30px -20px rgba(233,215,119,0.40);
  margin: 0 30px;
}
.modal-bg {
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
  position: absolute;
  content: ' ';
  left: 3px;
  right: 3px;
  top: 3px;
  bottom: 3px;
  border-radius: 30px;
  &:after {
    position: absolute;
    content: ' ';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: fade(@g-color-dark, 80%);
    border-radius: 30px;
  }
}
.modal-content {
  padding: 30px 30px 50px 30px;
  text-align: center;
  border-radius: 30px;
  position: relative;

  @media screen and (max-width: @g-screen-xs-max) {
    margin: 0 15px;
    padding: 30px 15px;
  }
  .section {
    padding: 0;
  }
  .form {
    margin: 0 auto;
    max-width: 360px;
  }
  .modal-title {
    font-family: @g-font-title;
    font-size: 44px;
    font-weight: 400;
    margin: 0 0 15px;
    padding: 0;
    text-shadow: 0 2px 2px rgba(0,0,0,.08)
  }

}

.modal-form {
  z-index: 1050;
  position: relative;
}

.modal-closeWrap {
  padding-bottom: 10px;
  text-align: right;
}

.modal-close,
.fr-close {
  border: none;
  background-color: inherit;
  transition: all .3s ease;
  background-image: url('../static/images/icon/close.svg');
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  background-size: 30px 30px;
  z-index: 4;
  &:hover {
    transform: scale(1.2);
  }
}
// FR CLOSE CUSTOM
.fr-close {
  top: 20px;
  right: 20px;
  &:hover {
    transform: scale(1.2);
  }
}

.fr-close-background,
.fr-close-icon {
  display: none;
}

.modal-backdrop {
  position: fixed;
  z-index: 1040;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(108, 100, 91, 0.9);
}

// FR BTN CUSTOM

.fr-side-button-background,
.fr-side-button-icon {
  display: none;
}

.fr-side {
  background-size: 40px 40px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  opacity: .7;
  &:first-child {
    left: 0;
  }
  &:last-child {
    right: 0;
  }
  &:hover {
    transform: scale(1.2);
    opacity: 1;
  }
  &:active {
    transform: scale(1.1);
  }
}
.fr-side-disabled {
  opacity: 0!important;
  cursor: text!important;
}

.fr-side-previous {
  background-image: url('../static/images/icon/left.svg');
  left: 15px;
}
.fr-side-next {
  background-image: url('../static/images/icon/right.svg');
  right: 15px;
}