.cover {
  height: 100vh;
  position: relative;
  background-position: top right;
  background-attachment: fixed;
  background-size: cover;
  min-height: 700px;
  max-height: 900px;
  @media screen and (max-width: @g-screen-xs-max) {
    background-attachment: scroll;
  }
  &:after {
    position: absolute;
    content: ' ';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: @g-color-dark;
    opacity: 0.8;
    z-index: 2;
  }
  &:before {
    position: absolute;
    content: ' ';
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    left: 0;
    right: 0;
    z-index: 9;
    background-image: url('../static/images/bottom.svg');
    height: 80px;
    bottom: -1px;
    @media screen and (max-width: @g-screen-sm-max) {
      height: 50px;
    }
    @media screen and (max-width: @g-screen-xs-max) {
      height: 30px;
    }
  }
  &-logo {
    text-align: center;
    padding: 100px 0;
    display: block;
    position: relative;
    z-index: 10;
    @media screen and (max-width: 1050px) {
      padding: 40px 0 50px;
    }
    &._sm {
      margin: 0 0 40px;
      @media screen and (max-width: @g-screen-xs-max) {
        margin-bottom: 0;
      }
      img {
        max-width: 200px;
      }
    }
    img {
      display: inline-block;
      width: 100%;
      max-width: 248px;
      margin: 0 auto;
      transition: .3s ease;
      @media screen and (max-width: @g-screen-xs-max) {
        max-width: 200px;
      }
      &:hover {
        transform: scale(1.05);
      }
    }
    a {
      display: block;
    }
  }

  &-top {
    margin-top: -300px;
    @media screen and (max-width: @g-screen-xs-max) {
      margin-top: -370px;
    }
  }
  &-video {
    padding: 6px;
    border-radius: 10px;
    background: @g-color-gradient;
    display: block;
    @media screen and (max-width: @g-screen-xs-max) {
      padding: 3px;
    }
    &:hover {
      .cover-videoImg {
        transform: scale(1.15);
      }
      .cover-videoInner {
        &:after {
          left: calc(~"50% - 35px");
          top: calc(~"50% - 35px");
          width: 70px;
          height: 70px;
          background-size: 70px 70px;
        }
      }
    }
  }
  &-videoInner {
    width: 100%;
    padding-bottom: 400px;
    display: block;
    background: #6C645B;
    box-shadow: 0 25px 30px -20px rgba(233,215,119,0.40);
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    &:after {
      transition: .4s ease;
      position: absolute;
      content: ' ';
      left: calc(~"50% - 40px");
      right: 0;
      top: calc(~"50% - 40px");
      bottom: 0;
      width: 80px;
      height: 80px;
      background-image: url('../static/images/icon/play.svg');
      background-repeat: no-repeat;
      background-size: 80px 80px;
    }
  }
  &-videoImg {
    transition: 1.1s ease;
    background-size: cover;
    background-position: top center;
    position: absolute;
    content: ' ';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
}