@ff: GoodVibesPro;
@ff2: Muli;

@font-face {
    font-family: '@{ff}';
    src: url('../static/fonts/@{ff}-Regular.eot'); /* IE9 Compat Modes */
    src: url('../static/fonts/@{ff}-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../static/fonts/@{ff}-Regular.woff2') format('woff2'), /* Modern Browsers */
        url('../static/fonts/@{ff}-Regular.woff') format('woff'), /* Modern Browsers */
        url('../static/fonts/@{ff}-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
        url('../static/fonts/@{ff}-Regular.svg') format('svg'); /* Legacy iOS */
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: '@{ff2}';
    src: url('../static/fonts/@{ff2}-Regular.eot'); /* IE9 Compat Modes */
    src: url('../static/fonts/@{ff2}-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../static/fonts/@{ff2}-Regular.woff2') format('woff2'), /* Modern Browsers */
        url('../static/fonts/@{ff2}-Regular.woff') format('woff'), /* Modern Browsers */
        url('../static/fonts/@{ff2}-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
        url('../static/fonts/@{ff2}-Regular.svg') format('svg'); /* Legacy iOS */
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: '@{ff2}';
    src: url('../static/fonts/@{ff2}-SemiBold.eot'); /* IE9 Compat Modes */
    src: url('../static/fonts/@{ff2}-SemiBold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../static/fonts/@{ff2}-SemiBold.woff2') format('woff2'), /* Modern Browsers */
        url('../static/fonts/@{ff2}-SemiBold.woff') format('woff'), /* Modern Browsers */
        url('../static/fonts/@{ff2}-SemiBold.ttf') format('truetype'); /* Safari, Android, iOS */
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: '@{ff2}';
    src: url('../static/fonts/@{ff2}-ExtraBold.eot'); /* IE9 Compat Modes */
    src: url('../static/fonts/@{ff2}-ExtraBold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../static/fonts/@{ff2}-ExtraBold.woff2') format('woff2'), /* Modern Browsers */
        url('../static/fonts/@{ff2}-ExtraBold.woff') format('woff'), /* Modern Browsers */
        url('../static/fonts/@{ff2}-ExtraBold.ttf') format('truetype'); /* Safari, Android, iOS */
    font-weight: 800;
    font-style: normal;
}