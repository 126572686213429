.footer {
  padding: 25px 0;
  position: relative;
  z-index: 2;
  background: @g-color-light;
  @media screen and (max-width: @g-screen-xs-max) {
    text-align: center;
  }
  p, a {
    font-size: 16px;
    line-height: 1.2;
    margin: 0;
    font-weight: 400;
    color: @g-color-black;
    text-decoration: none;
    b, span {
      font-weight: 700;
    }
    span {
      transition: .4s ease;
      text-decoration: underline;
    }
  }
  a {
    &:hover {
      span {
        color: @g-color-accent;
      }
    }
  }
  &-copyright {
  }
  &-dev {
    text-align: right;
    @media screen and (max-width: @g-screen-xs-max) {
      margin-top: 10px;
      text-align: center;
    }
  }
}