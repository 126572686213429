.video {
  text-decoration: none;
  position: relative;
  z-index: 9;
  &-list {
    margin: 10px 0 35px;
    text-align: center;
    li {
      margin-right: 15px;
      &:last-child {
        margin-right: 0;
      }
      @media screen and (max-width: @g-screen-sm-max) {
        margin-bottom: 10px;
      }
    }
    a {
      font-size: 18px;
      color: @g-color-light;
      line-height: 1;
      font-weight: 400;
      &:hover {
        color: @g-color;
        cursor: pointer;
      }
      &.current {
        color: @g-color;
        opacity: 1;
      }
    }
  }
  &-slideInner {
    padding: 6px;
    border-radius: 10px;
    background: @g-color-gradient;
    @media screen and (max-width: @g-screen-sm-max) {
      padding: 3px;
    }
    &._single {
      &:hover {
        .video-img {
          opacity: 1;
          transform: scale(1.2);
        }
        .video-meta {
          transform: translateY(200px);
        }
      }
      .video-slide {
        box-shadow: 0 8px 10px 0 fade(@g-color, 70%);
        width: 100%;
      }
      .video-img {
        filter: none;
      }
      .video-meta {
        transform: translateY(0);
      }
    }
  }
  &-bg {
    position: absolute;
    content: ' ';
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: @g-color-gradient;
    opacity: 0;
    z-index: -1;
    border-radius: 10px;
    transition: .9s ease;
  }
  &-slide {
    position: relative;
    display: flex;
    width: 850px;
    max-width: 90vw;
    height: 450px;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 10px;
    z-index: 9;
    box-shadow: 0 15px 20px 10px rgba(0,0,0,0.15);
    &:after {
      position: absolute;
      content: ' ';
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: @g-color-dark;
      z-index: -1;
    }
    &:hover {
      .video-img {
        transform: scale(1.1);
      }
    }
  }
  &-img {
    background: 50% no-repeat;
    background-size: cover;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    transition: .8s ease;
    filter: grayscale(100%);
    opacity: .55;
  }
  &-meta {
    position: absolute;
    content: ' ';
    left: 35px;
    bottom: 40px;
    transition: .7s ease;
    transform: translateY(200px);
    h3 {
      font-size: 20px;
      line-height: 1.3;
      font-weight: 700;
      font-family: @g-font-family;
      color: @g-color-light;
      position: relative;
      padding-left: 45px;
      text-transform: uppercase;
      text-shadow: 0 2px 2px rgba(0,0,0,.15);
      &:after {
        position: absolute;
        content: ' ';
        left: 0;
        top: calc(~"50% - 15px");
        width: 30px;
        height: 30px;
        background-size: 30px 30px;
        background-repeat: no-repeat;
        background-image: url('../static/images/icon/play-sm.svg');
        transition: .4s ease;
      }
    }
  }
  &-carousel {
    position: relative;
    transform: translateX(0);
    transition: 1.2s ease;
    z-index: 10;
    overflow-y: hidden;
    .owl-stage-outer {
      overflow: visible !important;
    }
    .owl-stage-outer .owl-stage {
      width: 10000em !important;
    }
    .owl-stage-outer .owl-item {
      transition: all 300ms ease-in-out;
    }
  }
}

.owl {
  &-item {
    &.current {
      .video-slide {
        box-shadow: 0 25px 30px -15px rgba(233,215,119,0.50);
      }
      .video-img {
        filter: none;
        opacity: 1;
      }
      .video-meta {
        transform: translateY(0);
      }
      .video-bg {
        opacity: 1;
      }
    }
  }
  &-dots {
    text-align: center;
    margin: 15px 0 0;
    @media screen and (max-width: @g-screen-sm-max) {
      display: none;
    }
    .owl-dot {
      display: inline-block;
      margin-right: 15px;
      width: 40px;
      height: 3px;
      background: @g-color!important;
      opacity: 0.3;
      transition: .4s ease;
      &:last-child {
        margin: 0;
      }
      &.active {
        opacity: 1;
      }
    }
  }
}

.owl-nav {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  position: relative;
  top: -250px;
}
.owl-btn {
  background: none;
  border: 0;
  position: absolute;
  transition: .4s ease;
  opacity: .7;
  @media screen and (max-width: @g-screen-sm-max) {
    opacity: 1;
  }
  &.disabled {
    opacity: 0!important;
    cursor: text!important;
  }
  &:first-child {
    left: 0;
  }
  &:last-child {
    right: 0;
  }
  &:hover {
    transform: scale(1.2);
    opacity: 1;
  }
  &:active {
    transform: scale(1.1);
  }
  .icon {
    &-prev,
    &-next {
      background-size: 40px 40px;
      width: 40px;
      height: 40px;
      cursor: pointer;
      display: block;
    }
    &-prev {
      background-image: url('../static/images/icon/left.svg');
    }

    &-next {
      background-image: url('../static/images/icon/right.svg');
    }
  }
}


.js-loading {
  transform: translateX(-1000px);
}


