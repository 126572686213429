.preloader {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  /* фоновый цвет */
  background: @g-color-black;
  z-index: 1001;
}

.preloader__image {
  position: relative;
  top: 50%;
  left: 50%;
  width: 140px;
  height: 140px;
  margin-top: -70px;
  margin-left: -70px;
  text-align: center;
  animation: preloader-rotate 2s infinite linear;
}

@keyframes preloader-rotate {
  0% { -webkit-transform: scale(1); }
  50% { -webkit-transform: scale(1.3); }
  100% { -webkit-transform: scale(1); }
}

.loaded_hiding .preloader {
  transition: 0.3s opacity;
  opacity: 0;
}

.loaded .preloader {
  display: none;
}

.section {
  position: relative;
  padding: 70px 0;
  .grid {
    z-index: 10;
    position: relative;
  }
  &-page {
    padding-top: 87px;
  }
  &.cover {
    padding: 87px 0 20px;
  }
  &-content {
    padding-top: 50px;
    @media screen and (max-width: @g-screen-xs-max) {
      padding-top: 10px;
    }
  }
  // BASE STYLE
  p, h1, h2, h4, b, strong, ul, li {
    line-height: 1.3;
    color: @g-color-gray;
  }
  h1, h2, h3, h4 {
    font-family: @g-font-title;
  }
  h2 {
    font-size: 50px;
    font-weight: 400;
    margin: 0 0 15px;
    padding: 0;
    text-shadow: 0 2px 2px rgba(0,0,0,0.08);
    @media screen and (max-width: @g-screen-sm-max) {
      font-size: 36px;
    }
    @media screen and (max-width: @g-screen-xs-max) {
      font-size: 42px;
    }
  }
  p {
    font-size: 15px;
    letter-spacing: .35px;
    @media screen and (max-width: @g-screen-sm-max) {
      font-size: 14px;
    }
  }
  b, strong {
    font-weight: 800;
  }
  &-meta {
    text-align: center;
    position: relative;
    padding-bottom: 47px;
    &:after {
      position: absolute;
      content: ' ';
      left: calc(~"50% - 87px");
      bottom: 25px;
      background-size: 177px 32px;
      width: 177px;
      height: 32px;
      background-image: url('../static/images/decor-2.svg');
      background-repeat: no-repeat;
    }
    &._light {
      h2 {
        color: @g-color-light;
      }
      &:after {
        background-image: url('../static/images/decor.svg');
      }
    }
  }

  &-btn {
    padding-top: 35px;
    text-align: center;
  }
  // SECTION WAVE
  &-wave {
    position: relative;
    &:after,
    &:before {
      position: absolute;
      content: ' ';
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      height: 0;
      left: 0;
      right: 0;
      z-index: 10;
    }
    &._bottom {
      &:before {
        background-image: url('../static/images/bottom.svg');
        height: 80px;
        bottom: 0;
        @media screen and (max-width: @g-screen-sm-max) {
          height: 50px;
        }
        @media screen and (max-width: @g-screen-xs-max) {
          height: 30px;
        }
      }
    }
    &._top {
      &:after {
        background-image: url('../static/images/top.svg');
        height: 80px;
        top: 0;
        @media screen and (max-width: @g-screen-sm-max) {
          height: 50px;
        }
        @media screen and (max-width: @g-screen-xs-max) {
          height: 30px;
        }
      }
    }
  }
  &-bg {
    background: @g-color-dark;
    position: absolute;
    content: ' ';
    z-index: 9;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    opacity: .8;
  }

  &-decor {
    text-align: center;
    margin: 0 auto;
    margin-bottom: 30px;
    padding-top: 32px;
    position: relative;
    z-index: 10;
    &:after {
      position: absolute;
      content: ' ';
      left: calc(~"50% - 87px");
      top: 0;
      background-size: 177px 32px;
      width: 177px;
      height: 32px;
      background-image: url('../static/images/decor.svg');
      background-repeat: no-repeat;
    }
  }
  // PRICE SINGLE
  &-price {
    .price-item {
      margin-bottom: 30px;
    }
  }
  // SECTION ITEM
  &-BG {
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    @media screen and (max-width: @g-screen-xs-max) {
      background-attachment: scroll;
    }
  }
  // DESC DIV
  &-desc {
    text-align: center;
    max-width: 900px;
    margin: 0 auto;
    padding: 0 15px;
    margin-bottom: 35px;
    p {
      color: @g-color-light;
      font-size: 16px;
      display: block;
      @media screen and (max-width: @g-screen-sm-max) {
        font-size: 14px;
      }
    }
  }
  // INFO DIV
  &-infoInner {
    padding: 2px;
    border-radius: 30px;
    background: @g-color-gradient;
    max-width: 240px;
    margin: 0 auto;
    margin-bottom: 25px;
  }
  &-info {
    border-radius: 30px;
    background: @g-color-dark;
    padding: 5px 15px;
    text-align: center;
    p {
      font-size: 16px;
      display: inline-block;
      font-weight: 700;
      color: @g-color-light;
    }
  }
}


// ABOUT BLOCK
.about {
  padding-top: 0;
  &-grid {
    padding-top: 60px;
  }
  &-item {
    text-align: center;
    margin-top: 15px;
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }
  img {
    width: 100%;
    max-width: 80px;
    transition: .3s ease;
  }
  &-desc {
    max-width: 215px;
    margin: 0 auto;
    margin-top: 15px;
    @media screen and (max-width: @g-screen-xs-max) {
      font-size: 14px;
    }
  }
}

// PHOTO BLOCK
.photo {
  padding-bottom: 0;
  &-item {
    background: @g-color-light;
    padding: 10px;
    margin: 0 0 30px;
    text-align: center;
    transition: .3s ease;
    @media screen and (max-width: @g-screen-xs-max) {
      padding: 5px 5px 10px 5px;
    }
    &:hover {
      box-shadow: 0 20px 30px -15px @g-color;
      transform: scale(1.05);
    }
  }
  &-img {
    width: 100%;
    padding-bottom: 180px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  h3 {
    margin-top: 10px;
    font-size: 18px;
    font-family: @g-font-family;
    font-weight: 400;
    letter-spacing: 2.4px;
    line-height: 1;
    display: inline-block;
    color: @g-color-black;
    @media screen and (max-width: @g-screen-xs-max) {
      font-size: 14px;
      letter-spacing: 1.4px;
    }
  }
  .section-btn {
    position: relative;
    z-index: 12;
    padding-top: 0;
    margin-bottom: -23px;
  }
}

// PRICE STYLE
.price {
  padding-bottom: 30px;
  &-grid {
    margin-bottom: 40px;
  }
  &-item {
    &._album {
      &:hover {
        .price-img {
          box-shadow: 0 20px 30px -10px @g-color;
        }
      }
    }
    &._photo {
      &:hover {
        .price-img {
          box-shadow: 0 20px 30px -10px @g-color;
          &:after {
            opacity: .7;
          }
        }
        img {
          filter: grayscale(100%);
        }
        .zoom {
          transform: scale(1);
        }
      }
    }
    .zoom {
      position: absolute;
      content: ' ';
      left: calc(~"50% - 20px");
      top: calc(~"50% - 21.5px");
      width: 40px;
      height: 43px;
      background-size: 40px 43px;
      background-image: url('../static/images/icon/zoom.svg');
      background-repeat: no-repeat;
      transform: scale(0);
      transition: .4s ease;
    }
  }
  &-img {
    border-radius: 10px;
    padding: 3px;
    background: @g-color-gradient;
    box-shadow: 0 15px 20px 10px rgba(0,0,0,0.15);
    line-height: 0;
    transition: .5s ease;
    position: relative;
    &:after {
      position: absolute;
      content: ' ';
      left: 3px;
      right: 3px;
      top: 3px;
      bottom: 3px;
      background: @g-color-black;
      border-radius: 10px;
      opacity: 0;
      transition: .3s ease;
    }
    img {
      border-radius: 10px;
      width: 100%;
      transition: .4s ease;
    }
  }
  &-meta {
    padding: 18px 0;
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
    }
    li {
      position: relative;
      font-size: 15px;
      line-height: 1;
      margin-bottom: 15px;
      padding-left: 20px;
      &:last-child {
        margin: 0;
      }
      &:after {
        position: absolute;
        content: ' ';
        left: 0;
        top: calc(~"50% - 5px");
        width: 9px;
        height: 9px;
        background-size: 9px 9px;
        background-repeat: no-repeat;
        background-image: url('../static/images/icon/li.svg');
      }
    }
    p {
      padding-top: 18px;
      font-size: 16px;
    }
  }
  &-title {
    font-family: @g-font-family!important;
    font-size: 24px;
    text-transform: uppercase;
    position: relative;
    padding-left: 40px;
    @media screen and (max-width: @g-screen-xs-max) {
      margin-top: 15px;
    }
    &:after {
      position: absolute;
      content: ' ';
      top: calc(~"50% - 13px");
      left: 0;
      width: 29px;
      height: 25px;
      background-size: 29px 25px;
      background-repeat: no-repeat;
    }
    color: @g-color-dark;
    &._photo {
      &:after {
        background-image: url('../static/images/icon/photo.svg');
      }
    }
    &._video {
      &:after {
        background-image: url('../static/images/icon/video.svg');
      }
    }
    &._album {
      &:after {
        background-image: url('../static/images/icon/album.svg');
      }
    }

  }
}


// VIDEO BLOCK
.video {
  padding-bottom: 0;
  .section-decor {
    margin: 25px 0 0;
  }
  .section-btn {
    position: relative;
    z-index: 12;
    bottom: -21px;
    padding-top: 25px;
  }
}

// REVIEW BLOCK
.reviews {
  padding-bottom: 0;
  .owl-item {
    &.active {
      .reviews-itemInner {
        filter: grayscale(0);
        opacity: 1;
      }
    }
    &.center {
      .reviews-itemInner {
        box-shadow: 0 8px 10px 0 fade(@g-color, 70%);
      }
    }

  }
  .section-decor {
    margin-top: 25px;
  }
  &-itemInner {
    box-shadow: 0 8px 10px 10px rgba(0,0,0,0.15);
    border-radius: 10px;
    transition: .4s ease;
    filter: grayscale(100%);
    opacity: .4;
    &:hover {
      box-shadow: 0 8px 10px 0 fade(@g-color, 70%);
    }
  }
  &-item {
    margin-bottom: 25px;
  }
  &-bg {
    position: absolute;
    content: ' ';
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: @g-color-gradient;
    opacity: 0;
    z-index: -1;
    border-radius: 10px;
    transition: .9s ease;
  }
  img {
    width: 100%;
    max-width: 263px;
    border-radius: 10px;
  }
}

// ALBUM BLOCK
.album {
  padding-bottom: 70px;
  .section-btn {
    padding-top: 0;
  }
  .price-item {
    @media screen and (max-width: @g-screen-sm-max) {
      margin-bottom: 30px;
    }
  }
  .price-title {
    @media screen and (max-width: @g-screen-sm-max) {
      margin-top: 0;
    }
  }
}

// CONTACT BLOCK
.contact {
  @media screen and (max-width: @g-screen-sm-max) {
    background-position: top left;
  }
}
