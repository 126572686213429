.form {
  width: 100%;
  max-width: 400px;
  display: block;
  margin: 0 auto;
  position: relative;
  &._sm {
    margin-top: 10px;
  }
  &-field {
    width: 100%;
    border: none;
    background: none;
    padding: 10px 28px;
    background: @g-color-light;
    outline: none;
    font-size: 18px;
    line-height: 1;
    color: @g-color-black;
    transition: .4s ease;
    box-shadow: 0 15px 14px -10px rgba(233,215,119,0.30);
    border-radius: 6px;
    &:hover {
      box-shadow: 0 20px 10px -15px @g-color;
    }
    &:active,
    &:focus,
    &:hover {
      color: @g-color-black;
    }
    &._area {
      resize: none;
      min-height: 176px;
      line-height: 1.3;
      @media screen and (max-width: @g-screen-xs-max) {
        min-height: 150px;
      }
    }
  }
  &-group {
    margin-bottom: 25px;
  }
  &-btn {
    margin: 15px 0 15px 0;
    text-align: center;
  }
  &-success {
    text-align: center;
    position: absolute;
    border-radius: 10px;
    content: ' ';
    display: none;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 120px 30px 30px;
    background: @g-color-light;
    z-index: 3;
    h3 {
      font-size: 40px;
      font-family: @g-font-family;
      margin-bottom: 20px;
    }
    p {
      margin-top: 15px;
      font-size: 16px;
    }
  }
}




// BASE CUSTOM
input:-webkit-autofill {
  -webkit-text-fill-color: @g-color-black;
}

input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 5px 0 fade(@g-color-gray-3, 5%), inset 0 0 0 50px fade(@g-color-gray-3, 5%);
  -webkit-text-fill-color: @g-color-black;
}