@grid-cols: 12;
@grid-gutter: 30px;
@grid-tab: (720px + @grid-gutter);
@grid-desk: (940px + @grid-gutter);
@grid-desk-lg: (1140px + @grid-gutter);
@grid-sm: @grid-tab;
@grid-md: @grid-desk;
@grid-lg: @grid-desk-lg;

.grid {
  margin-right: auto;
  margin-left: auto;
  padding-left: @grid-gutter / 2;
  padding-right: @grid-gutter / 2;
  &:before,
  &:after {
    content: '';
    display: table;
  }
  &:after {
    clear: both;
  }
  @media (min-width: @g-screen-sm-min) {
    width: @grid-sm;
  }
  @media (min-width: @g-screen-md-min) {
    width: @grid-md;
  }
  @media (min-width: @g-screen-lg-min) {
    width: @grid-lg;
  }

  &._fluid {
    width: 100%;
    max-width: 1440px;
  }

  &-row {
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left:  @grid-gutter / -2;
    margin-right: @grid-gutter / -2;

    &._gutter {
      &-false {
        margin: 0;
        > .grid-col {
          padding: 0;
        }
      }
    }

    &._align {
      &-start {
        &-xs {
          justify-content: flex-start;
          text-align: start;
        }
        &-sm {
          @media (min-width: @g-screen-sm-min) {
            justify-content: flex-start;
            text-align: start;
          }
        }
        &-md {
          @media (min-width: @g-screen-md-min) {
            justify-content: flex-start;
            text-align: start;
          }
        }
        &-lg {
          @media (min-width: @g-screen-lg-min) {
            justify-content: flex-start;
            text-align: start;
          }
        }
      }
      &-center {
        &-xs {
          justify-content: center;
          text-align: center;
        }
        &-sm {
          @media (min-width: @g-screen-sm-min) {
            justify-content: center;
            text-align: center;
          }
        }
        &-md {
          @media (min-width: @g-screen-md-min) {
            justify-content: center;
            text-align: center;
          }
        }
        &-lg {
          @media (min-width: @g-screen-lg-min) {
            justify-content: center;
            text-align: center;
          }
        }
      }
      &-end {
        &-xs {
          justify-content: flex-end;
          text-align: end;
        }
        &-sm {
          @media (min-width: @g-screen-sm-min) {
            justify-content: flex-end;
            text-align: end;
          }
        }
        &-md {
          @media (min-width: @g-screen-md-min) {
            justify-content: flex-end;
            text-align: end;
          }
        }
        &-lg {
          @media (min-width: @g-screen-lg-min) {
            justify-content: flex-end;
            text-align: end;
          }
        }
      }
      &-top {
        &-xs {
          align-items: flex-start;
        }
        &-sm {
          @media (min-width: @g-screen-sm-min) {
            align-items: flex-start;
          }
        }
        &-md {
          @media (min-width: @g-screen-md-min) {
            align-items: flex-start;
          }
        }
        &-lg {
          @media (min-width: @g-screen-lg-min) {
            align-items: flex-start;
          }
        }
      }
      &-middle {
        &-xs {
          align-items: center;
        }
        &-sm {
          @media (min-width: @g-screen-sm-min) {
            align-items: center;
          }
        }
        &-md {
          @media (min-width: @g-screen-md-min) {
            align-items: center;
          }
        }
        &-lg {
          @media (min-width: @g-screen-lg-min) {
            align-items: center;
          }
        }
      }
      &-bottom {
        &-xs {
          align-items: flex-end;
        }
        &-sm {
          @media (min-width: @g-screen-sm-min) {
            align-items: flex-end;
          }
        }
        &-md {
          @media (min-width: @g-screen-md-min) {
            align-items: flex-end;
          }
        }
        &-lg {
          @media (min-width: @g-screen-lg-min) {
            align-items: flex-end;
          }
        }
      }
    }

    &._dist {
      &-around {
        &-xs {
          justify-content: space-around;
        }
        &-sm {
          @media (min-width: @g-screen-sm-min) {
            justify-content: space-around;
          }
        }
        &-md {
          @media (min-width: @g-screen-md-min) {
            justify-content: space-around;
          }
        }
        &-lg {
          @media (min-width: @g-screen-lg-min) {
            justify-content: space-around;
          }
        }
      }
      &-between {
        &-xs {
          justify-content: space-between;
        }
        &-sm {
          @media (min-width: @g-screen-sm-min) {
            justify-content: space-between;
          }
        }
        &-md {
          @media (min-width: @g-screen-md-min) {
            justify-content: space-between;
          }
        }
        &-lg {
          @media (min-width: @g-screen-lg-min) {
            justify-content: space-between;
          }
        }
      }
    }

    &._reverse {
      flex-direction: row-reverse;
    }
  }

  &-col {
    position: relative;
    flex: 0 0 auto;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    padding-left: @grid-gutter / 2;
    padding-right: @grid-gutter / 2;

    &._order {
      &-first {
        &-xs {
          order: -1;
        }
        &-sm {
          @media (min-width: @g-screen-sm-min) {
            order: -1;
          }
        }
        &-md {
          @media (min-width: @g-screen-md-min) {
            order: -1;
          }
        }
        &-lg {
          @media (min-width: @g-screen-lg-min) {
            order: -1;
          }
        }
      }

      &-last {
        &-xs {
          order: 1;
        }
        &-sm {
          @media (min-width: @g-screen-sm-min) {
            order: 1;
          }
        }
        &-md {
          @media (min-width: @g-screen-md-min) {
            order: 1;
          }
        }
        &-lg {
          @media (min-width: @g-screen-lg-min) {
            order: 1;
          }
        }
      }
    }

    &._reverse {
      flex-direction: column-reverse;
    }
  }

}


// --- Framework

// generating columns
.make-grid(xs);
@media (min-width: @g-screen-sm-min) {
  .make-grid(sm);
}
@media (min-width: @g-screen-md-min) {
  .make-grid(md);
}
@media (min-width: @g-screen-lg-min) {
  .make-grid(lg);
}

// generate the extra small columns
.make-xs-column(@columns; @gutter: @grid-gutter) {
  max-width: percentage((@columns / @grid-cols));
  flex-basis: percentage((@columns / @grid-cols));
}
.make-xs-column-offset(@columns) {
  margin-left: percentage((@columns / @grid-cols));
}
.make-xs-column-push(@columns) {
  left: percentage((@columns / @grid-cols));
}
.make-xs-column-pull(@columns) {
  right: percentage((@columns / @grid-cols));
}

// generate the small columns
.make-sm-column(@columns; @gutter: @grid-gutter) {
  @media (min-width: @g-screen-sm-min) {
    max-width: percentage((@columns / @grid-cols));
    flex-basis: percentage((@columns / @grid-cols));
  }
}
.make-sm-column-offset(@columns) {
  @media (min-width: @g-screen-sm-min) {
    margin-left: percentage((@columns / @grid-cols));
  }
}
.make-sm-column-push(@columns) {
  @media (min-width: @g-screen-sm-min) {
    left: percentage((@columns / @grid-cols));
  }
}
.make-sm-column-pull(@columns) {
  @media (min-width: @g-screen-sm-min) {
    right: percentage((@columns / @grid-cols));
  }
}

// generate the medium columns
.make-md-column(@columns; @gutter: @grid-gutter) {
  @media (min-width: @g-screen-md-min) {
    max-width: percentage((@columns / @grid-cols));
    flex-basis: percentage((@columns / @grid-cols));
  }
}
.make-md-column-offset(@columns) {
  @media (min-width: @g-screen-md-min) {
    margin-left: percentage((@columns / @grid-cols));
  }
}
.make-md-column-push(@columns) {
  @media (min-width: @g-screen-md-min) {
    left: percentage((@columns / @grid-cols));
  }
}
.make-md-column-pull(@columns) {
  @media (min-width: @g-screen-md-min) {
    right: percentage((@columns / @grid-cols));
  }
}

// generate the large columns
.make-lg-column(@columns; @gutter: @grid-gutter) {
  @media (min-width: @g-screen-lg-min) {
    max-width: percentage((@columns / @grid-cols));
    flex-basis: percentage((@columns / @grid-cols));
  }
}
.make-lg-column-offset(@columns) {
  @media (min-width: @g-screen-lg-min) {
    margin-left: percentage((@columns / @grid-cols));
  }
}
.make-lg-column-push(@columns) {
  @media (min-width: @g-screen-lg-min) {
    left: percentage((@columns / @grid-cols));
  }
}
.make-lg-column-pull(@columns) {
  @media (min-width: @g-screen-lg-min) {
    right: percentage((@columns / @grid-cols));
  }
}

.calc-grid-column(@index, @class, @type) when (@type = width) and (@index > 0) {
  .grid-col._@{class}-@{index} {
    max-width: percentage((@index / @grid-cols));
    flex-basis: percentage((@index / @grid-cols));
  }
}
.calc-grid-column(@index, @class, @type) when (@type = push) and (@index > 0) {
  .grid-col._@{class}-push-@{index} {
    left: percentage((@index / @grid-cols));
  }
}
.calc-grid-column(@index, @class, @type) when (@type = push) and (@index = 0) {
  .grid-col._@{class}-push-0 {
    left: auto;
  }
}
.calc-grid-column(@index, @class, @type) when (@type = pull) and (@index > 0) {
  .grid-col._@{class}-pull-@{index} {
    right: percentage((@index / @grid-cols));
  }
}
.calc-grid-column(@index, @class, @type) when (@type = pull) and (@index = 0) {
  .grid-col._@{class}-pull-0 {
    right: auto;
  }
}
.calc-grid-column(@index, @class, @type) when (@type = offset) {
  .grid-col._@{class}-offset-@{index} {
    margin-left: percentage((@index / @grid-cols));
  }
}

// basic looping in less
.loop-grid-columns(@index, @class, @type) when (@index >= 0) {
  .calc-grid-column(@index, @class, @type);
  // next iteration
  .loop-grid-columns((@index - 1), @class, @type);
}

// create grid for specific class
.make-grid(@class) {
  .loop-grid-columns(@grid-cols, @class, width);
  .loop-grid-columns(@grid-cols, @class, pull);
  .loop-grid-columns(@grid-cols, @class, push);
  .loop-grid-columns(@grid-cols, @class, offset);
}