.btn {
  background: #6C645B;
  box-shadow: 0 10px 14px -8px @g-color;
  border-radius: 30px;
  padding: 13px 24px;
  font-size: 16px;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 1.3px;
  text-transform: uppercase;
  transition: .4s ease;
  border: 0;
  &:hover {
    box-shadow: 0 16px 23px -8px @g-color;
  }
  .text {
    background: @g-color-gradient-2;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &-inner {
    padding: 2px;
    background: @g-color-gradient;
    display: inline-block;
    border-radius: 30px;
    transition: .4s ease;
    &:hover {
      transform: scale(1.1);
    }
  }
  &._sm {
    font-size: 12px;
    padding: 8px 11px;
    box-shadow: 0 20px 15px -12px @g-color;
    &:hover {
      box-shadow: 0 20px 15px -8px @g-color;
    }
  }
}


@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(212,77,77,.8)
  }
  70% {
    box-shadow: 0 0 0 15px rgba(212,77,77,0)
  }
  to {
    box-shadow: 0 0 0 0 rgba(212,77,77,0)
  }
}